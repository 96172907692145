import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { BiSolidTag } from "react-icons/bi";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";

const GameTag = ({ game }) => {
  return (
    <Box>
      {game.tag && (
        <Box>
          <Image
            src={`${COMMON_WEB_PATH}/WSKYgameSample/icon/${game.tag.toLowerCase()}-label.svg`}
            alt={`${game.tag} Games`}
            className="absolute top-[-3px] left-[-2px] z-[1]"
          />
          <Box className="absolute top-[-4px] left-[-4px] gap-[-5px] w-[41px] h-[43px] flex flex-col items-center justify-center font-bold text-white z-[1]">
            <Text className="text-xs rotate-[315deg]">
              {game.tag.toUpperCase()}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GameTag;
