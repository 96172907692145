import React, { forwardRef, useRef } from "react";
import WrapperTitle from "./WrapperTitle";
import { BiLogoTelegram, BiSolidPhone } from "react-icons/bi";
import { BsPersonFill, BsFillBuildingFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { Form, message, Space } from "antd";
import SpecText from "./SpecText";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { IoIosMail } from "react-icons/io";

import {
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

const ContactV3 = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lv5v4a1",
        "template_frsan7q",
        form.current,
        "CUOsBqNSzl_dSYuQo"
      )
      .then(
        (result) => {
          console.log(result.text);
          message.success(i18n("SUCCESS!"));
        },
        (error) => {
          console.log(error.text);
          message.error(i18n("FAILED..."));
        }
      );
  };

  const socialIconClass = "text-4xl text-white m-2 p-1";

  const socialLinks = [
    {
      to: "#",
      icon: <FaFacebookF className={socialIconClass} />,
    },
    {
      to: "#",
      icon: <FaXTwitter className={socialIconClass} />,
    },
    {
      to: "#",
      icon: <FaYoutube className={socialIconClass} />,
    },
    {
      to: "#",
      icon: <FaInstagram className={socialIconClass} />,
    },
    {
      to: "#",
      icon: <BiLogoTelegram className={socialIconClass} />,
    },
  ];

  return (
    <Flex flexDirection={"column"} gap={12} ref={ref}>
      <Flex
        flexDirection={"column"}
        textAlign={"left"}
        gap={{ base: 6, md: 10 }}
        className="px-6 py-4 gap-x-10 gap-y-4"
      >
        <h1 className="text-black text-4xl md:text-5xl font-bold">
          {i18n("Get our exclusive offer!")}
        </h1>

        <form ref={form} onSubmit={sendEmail}>
          <Flex
            flexDirection={{
              base: "column",
              md: "row",
            }}
            gap={"3"}
          >
            <div className="inline-flex flex-col relative w-full">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  style={{ fontSize: "28px" }}
                >
                  <IoIosMail color="black" />
                </InputLeftElement>
                <Input
                  type="email"
                  name="email"
                  placeholder={i18n("Email")}
                  className="text-light-black"
                  borderWidth="0 0 2px 0"
                  borderColor="black"
                  borderRadius="0"
                  _focus={{
                    borderColor: "black",
                    boxShadow: "none",
                  }}
                  fontSize={"2xl"}
                />
              </InputGroup>
            </div>
            <Button
              type="submit"
              htmlType="submit"
              className="submit-button rounded-[5px] !px-[15px] !py-[9px] md:!py-[6px] !h-auto"
              fontSize={{
                base: "xl",
                md: "lg",
              }}
            >
              {i18n("Submit")}
            </Button>
          </Flex>
        </form>
      </Flex>
      <Flex
        flexDirection={"column"}
        textAlign={"left"}
        gap={{ base: 6, md: 10 }}
        className="px-6 py-4 gap-x-10 gap-y-4"
      >
        <h1 className="text-black text-4xl md:text-5xl font-bold">
          {i18n("Contact Us")}
        </h1>

        <Flex flexDirection={"column"} gap={2}>
          {/* <Flex
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={3}
            fontSize={"2xl"}
          >
            <FaPhoneAlt className="text-main-color" />
            <Text className="text-light-gray-3">+886 123456789</Text>
          </Flex> */}
          <Flex
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={3}
            fontSize={"2xl"}
          >
            <IoMail className="text-main-color" fontSize={"xl"} />
            <Text className="text-light-gray-3">wenhao@worthysky.com</Text>
          </Flex>
        </Flex>

        <Flex justifyContent={"flex-start"} alignItems={"center"} gap={3}>
          {socialLinks.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              target="_blank"
              className=" bg-black hover:bg-fifth-color rounded-[50%]  flex justify-center items-center"
            >
              {link.icon}
            </Link>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
});

export default ContactV3;
