import neko from "../images/Carousel/neko3.webp";
import nekoLogo from "../images/Carousel/neko-logo.webp";
import nekoMobile from "../images/Carousel/neko-mobile3.webp";
import blastx from "../images/Carousel/blastx2.webp";
import blastxLogo from "../images/Carousel/blastx-logo.webp";
import blastxMobile from "../images/Carousel/blastx-mobile2.webp";
//import fish from "../images/Carousel/fish2.webp";
import fishLogo from "../images/Carousel/fish-logo.webp";
import fishMobile from "../images/Carousel/fish-mobile2.webp";
import goalbaby from "../images/Carousel/goalbaby2.webp";
import goalbabyLogo from "../images/Carousel/goalbaby-logo.webp";
import goalbabyMobile from "../images/Carousel/goalbaby-mobile2.webp";
import holmes from "../images/Carousel/holmes3.webp";
import holmesLogo from "../images/Carousel/holmes-logo.webp";
import holmesMobile from "../images/Carousel/holmes-mobile3.webp";
import sister from "../images/Carousel/sister2.webp";
import sisterLogo from "../images/Carousel/sister-logo.webp";
import sisterMobile from "../images/Carousel/sister-mobile2.webp";
import idol from "../images/Carousel/idol3.webp";
import idolLogo from "../images/Carousel/idol-logo.webp";
import idolMobile from "../images/Carousel/idol-mobile3.webp";
//import sicbo from "../images/Carousel/sicbo2.webp";
import sicboLogo from "../images/Carousel/sicbo-logo.webp";
import sicboMobile from "../images/Carousel/sicbo-mobile2.webp";
import simbad from "../images/Carousel/simbad2.webp";
import simbadLogo from "../images/Carousel/simbad-logo.webp";
import simbadMobile from "../images/Carousel/simbad-mobile2.webp";
import treasure from "../images/Carousel/treasure2.webp";
import treasureLogo from "../images/Carousel/treasure-logo.webp";
import treasureMobile from "../images/Carousel/treasure-mobile2.webp";
import witch from "../images/Carousel/witch2.webp";
import witchLogo from "../images/Carousel/witch-logo.webp";
import witchMobile from "../images/Carousel/witch-mobile2.webp";
//import xio from "../images/Carousel/xio2.webp";
import xioLogo from "../images/Carousel/xio-logo.webp";
import xioMobile from "../images/Carousel/xio-mobile2.webp";
import queen from "../images/Carousel/queen.webp";
import queenLogo from "../images/Carousel/queen-logo.webp";
import queenMobile from "../images/Carousel/queen-mobile.webp";
import goddess from "../images/Carousel/goddess3.webp";
import goddessLogo from "../images/Carousel/goddess-logo.webp";
import goddessMobile from "../images/Carousel/goddess-mobile2.webp";
import rabbitgirl from "../images/Carousel/rabbitgirl3.webp";
import rabbitgirlLogo from "../images/Carousel/rabbitgirl-logo.webp";
import rabbitgirlMobile from "../images/Carousel/rabbitgirl-mobile2.webp";
import hottieLogo from "../images/Carousel/hottie-logo.webp";
import hottie from "../images/Carousel/hottie.webp";
import hottieMobile from "../images/Carousel/hottie-mobile.webp";
import spa from "../images/Carousel/spa3.webp";
import spaLogo from "../images/Carousel/spa-logo.webp";
import spaMobile from "../images/Carousel/spa-mobile2.webp";
import Napoleon from "../images/Carousel/Napoleon.webp";
import NapoleonLogo from "../images/Carousel/Napoleon-logo.webp";
import NapoleonMobile from "../images/Carousel/Napoleon-mobile.webp";
import poseidon from "../images/Carousel/poseidon3.webp";
import poseidonLogo from "../images/Carousel/poseidon-logo.webp";
import poseidonMobile from "../images/Carousel/poseidon-mobile2.webp";
import bikini from "../images/Carousel/bikini.webp";
import bikiniLogo from "../images/Carousel/bikini-logo.webp";
import bikiniMobile from "../images/Carousel/bikini-mobile.webp";
import tron from "../images/Carousel/tron.webp";
import tronLogo from "../images/Carousel/tron-logo.webp";
import tronMobile from "../images/Carousel/tron-mobile.webp";
import pig from "../images/Carousel/pig.webp";
import pigLogo from "../images/Carousel/pig-logo.webp";
import pigMobile from "../images/Carousel/pig-mobile.webp";
import { COMMON_WEB_PATH } from "./stylesConfig";

const CAROUSEL_PATH = `${COMMON_WEB_PATH}/WSKYgameSample`;

export const carouselData = [
  {
    title: "Streaming Girl",
    uid: "SL2445",
    image: `${CAROUSEL_PATH}/Carousel/carousels_1.webp`,
    logo: `${CAROUSEL_PATH}/rabbitgirl-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/Carousel/carousels_1.webp`,
    Headtitle: "The cute bunny lady appears!",
    description: "Play games in the exclusive live broadcast room!",
    Demolink: "https://game.helloholyfa.com/slot/sl2445/",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "15000 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "1247%",
  },
  {
    title: "Miss Holmes",
    uid: "SL2446",
    image: `${CAROUSEL_PATH}/Carousel/carousels_2.webp`,
    logo: `${CAROUSEL_PATH}/holmes-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/Carousel/carousels_2.webp`,
    Headtitle: "Enjoy the intellectual showdown",
    description: "Help us reclaim the priceless crown!",
    Demolink: "https://game.helloholyfa.com/slot/sl2446/",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "3333 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "320%",
  },
  {
    title: "Goddess Wonderland",
    uid: "SL2447",
    image: `${CAROUSEL_PATH}/Carousel/carousels_3.webp`,
    logo: `${CAROUSEL_PATH}/goddess-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/Carousel/carousels_3.webp`,
    Headtitle: "Meet the Goddess of Wealth",
    description: "Step into a fairyland, and gain infinite money.",
    Demolink: "https://game.helloholyfa.com/slot/sl2447/",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "5000 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "175%",
  },
];
