import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { gameData } from "../helpers/gamelist";
import levelICON_mobile from "../images/icon/levelICON_mobile.webp";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaPlay, FaStar, FaUser } from "react-icons/fa";
import useWebsiteInfo from "../hooks/useWebsiteInfo";

const TopGames = ({ promo }) => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_game = (key) => t(`game.${key}`);

  const { is911vip, is5285 } = useWebsiteInfo();

  const splideRef = useRef(null);
  const [randomGames, setRandomGames] = useState([]);
  // 根據當前語言設置圖片路徑
  const currentLang = i18nInstance.language || "en";
  const IMAGE_PATH = `${COMMON_WEB_PATH}/levelupGameIcon/${currentLang}`;
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  // 生成隨機數
  const generateRandomRating = () => {
    // 生成 4.0 到 5.0 之間的隨機數，並取到小數點第一位
    return (Math.random() * (5.0 - 4.0) + 4.0).toFixed(1);
  };

  const generateRandomUsers = () => {
    // 生成 10000000 到 99999999 之間的隨機數
    const randomNum = Math.floor(
      Math.random() * (99999999 - 10000000) + 10000000
    );
    // 把數字轉為字符串並加上千分位逗號
    return randomNum.toLocaleString();
  };

  useEffect(() => {
    const currentGameData = gameData(currentLang);

    // 定義需要過濾的遊戲 UID
    const filteredGameUids = ["CR2401", "CR2404", "CR2405", "CR2406"];

    // 先根據網站信息過濾掉特定遊戲
    const filteredGameData = currentGameData.filter(
      (game) => !(filteredGameUids.includes(game.uid) && (is911vip || is5285))
    );

    const getRandomGames = () =>
      [...filteredGameData]
        .sort(() => 0.5 - Math.random())
        .slice(0, 3)
        .map((game) => ({
          ...game,
          rating: generateRandomRating(),
          users: generateRandomUsers(),
        }));

    setRandomGames(getRandomGames());
    const interval = setInterval(
      () => setRandomGames(getRandomGames()),
      100000
    );
    return () => clearInterval(interval);
  }, [currentLang, is911vip, is5285]);

  return (
    <Box className="relative rounded-[26px] p-6 md:p-4 pt-2 flex gap-4 mt-4 md:mt-8 md:mr-[26px] md:menu-box-shadow">
      <Flex
        width={"100%"}
        justifyContent={"start"}
        flexDirection={"column"}
        alignItems={"start"}
        gap={2}
      >
        {/* <div className="flex flex-col text-center">
          <div className="w-full text-center flex justify-between items-center">
            <img
              src={`${ICON_PATH}/levelICON_mobile.webp`}
              alt="levelICON"
              className="move-up-down mt-[-14px] w-[80px]"
            />
            <div>
              <h1 className="text-[#ff644d] text-3xl font-custom">
                {i18n("Level Up")}
              </h1>
              <h1 className="text-[#796794] text-xs font-custom">
                {i18n("Your Business")} {i18n("With")}
              </h1>
            </div>
          </div>
        </div> */}
        <Flex gap={3} justifyContent={"start"}>
          <Image
            src={`${COMMON_WEB_PATH}/WSKYgameSample/icon/popular.svg`}
            alt="Top Games"
            className="move-up-down mt-[-14px] w-[30px]"
          />
          <h1 className="text-black text-xl md:text-lg font-black">
            {i18n("TopGames")}
          </h1>
        </Flex>

        <Flex flexDirection={"column"} w={"100%"} gap={3}>
          {randomGames.map((game) => (
            <Box
              key={game.uid}
              className="gameSlide-container group"
              display={{ base: "flex", md: "block" }}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={4}
            >
              <Link to={`/play/${game.uid}`}>
                <Box position="relative">
                  <LazyLoadImage
                    src={game.image}
                    alt={game.title}
                    className="rounded-[10px] md:rounded-[18px] object-cover w-full h-[100px] md:h-[120px]"
                  />
                  <Box
                    className="overlay opacity-0 group-hover:opacity-100 hover:opacity-100 transition-opacity duration-200"
                    rounded={"18px"}
                    display={{ base: "none", md: "block" }}
                  >
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      zIndex={2}
                      cursor="pointer"
                      rounded={"full"}
                      padding={4}
                      backgroundColor={"#6BEABA"}
                    >
                      <FaPlay size={20} className="text-black" />
                    </Box>
                  </Box>
                </Box>
              </Link>

              <Flex flexDirection={"column"} gap={1} rounded={"18px"}>
                <Link to={`/play/${game.uid}`}>
                  <Box
                    className="play-button rounded-[5px] !px-[8px] !py-[6px] !mt-2 hover:!bg-main-color group-hover:!bg-main-color"
                    textAlign={"center"}
                    display={{ base: "none", md: "block" }}
                  >
                    {i18n("Play Demo")}
                  </Box>
                </Link>
                <Text
                  className=" text-black transition-colors duration-200 text-2xl md:text-base font-bold"
                  w={"100%"}
                  maxW={{ base: "200px", md: "fit-content" }}
                  isTruncated
                >
                  {i18n_game(game.title)}
                </Text>
                <Flex alignItems="center" gap={4}>
                  <Flex alignItems="center" gap={1}>
                    <FaStar color="#ffc400" size={14} />
                    <Text className="text-light-gray-2 text-sm">
                      {game.rating}
                    </Text>
                  </Flex>

                  <Flex alignItems="center" gap={1}>
                    <FaUser color="#4ED89E" size={14} />
                    <Text className="text-light-gray-2 text-sm">
                      {game.users}
                    </Text>
                  </Flex>
                </Flex>
                {/* <Link to={game.Morelink}>
                    <Box className="more-button rounded-[5px] !px-[8px] !py-[6px]">
                      {i18n("Read More")}
                    </Box>
                  </Link> */}
              </Flex>
              <Link to={`/play/${game.uid}`}>
                <Box
                  rounded={"18px"}
                  display={{ base: "block", md: "none" }}
                  className="group"
                >
                  <Box
                    cursor="pointer"
                    rounded={"full"}
                    padding={4}
                    backgroundColor={"#6BEABA"}
                    _hover={{ backgroundColor: "#48D498" }}
                  >
                    <FaPlay
                      size={20}
                      className="text-black group-hover:text-white"
                    />
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default TopGames;
