import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { gameData } from "../../helpers/gamelist";
import Iframe from "./components/iframe";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import GameInfo from "./components/gameInfo";
import { Text } from "@chakra-ui/react";

const { Option } = Select;

function PlayGame() {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const i18n_game = (key) => t(`game.${key}`);
  const currentLang = i18nInstance.language || "tw";

  const { gameUid } = useParams(); //useParams是一個hook，他的作用是獲取路由參數
  const [selectedLanguage, setSelectedLanguage] = useState("tw"); // 預設語言是繁體中文

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value); //把value傳進去selectedLanguage
    // console.log(`selected ${value}`);
  };

  // 獲取當前語言的遊戲數據
  const currentGameData = useMemo(() => {
    return gameData(currentLang);
  }, [currentLang]);

  const gameConfig = useMemo(() => {
    //useMemo是一個hook，他的作用是為了避免重複渲染，當gameUid改變的時候，才會重新渲染
    return currentGameData.find((game) => {
      //find是一個方法，他的作用是找到gameData裡面的game，並且把他返回出來
      return game.uid === gameUid; //如果gameData裡面的game的uid等於gameUid，就返回出來
    });
  }, [gameUid, currentGameData]);

  const {
    //Demolink = "",
    type = "",
    title = "",
    direction = "",
    intro = "",
    maxOdds = "",
    RTP = "",
    Resolution = "",
  } = gameConfig || {};
  //意思是如果gameConfig里面没有{}裡面這些属性，就给他一个空字符串

  const getDemoLink = () => {
    if (!gameConfig?.Demolink) return "";
    const baseUrl = gameConfig.Demolink;

    // 檢查URL是否已經包含查詢參數
    const separator = baseUrl.includes("?") ? "&" : "?";
    return `${baseUrl}${separator}lang=${selectedLanguage}`;
  };

  const Demolink = getDemoLink();
  //這個是demoLink的組件，他的作用是把gameConfig裡面的Demolink和selectedLanguage這個props傳進去

  //根據不同的遊戲，顯示不同的語言選項
  const renderLanguageOptions = () => {
    const typeOneLanguages = [
      { value: "en", label: "English" },
      { value: "zh-cht", label: "繁體中文" },
      { value: "zh-chs", label: "简体中文" },
      { value: "pt", label: "Português" },
      { value: "es", label: "Español" },
      { value: "my", label: "Melayu" },
      { value: "ph", label: "Filipino" },
      { value: "vn", label: "Tiếng Việt" },
    ];

    const typeTwoLanguages = [
      { value: "en", label: "English" },
      { value: "zh-cht", label: "繁體中文" },
      { value: "zh-chs", label: "简体中文" },
      { value: "pt", label: "Português" },
      { value: "es", label: "Español" },
      { value: "my", label: "Melayu" },
      { value: "ph", label: "Filipino" },
    ];

    const typeThreeLanguages = [
      { value: "en", label: "English" },
      { value: "zh-cht", label: "繁體中文" },
      { value: "th", label: "แบบไทย" },
    ];

    // 根據遊戲類型返回不同的語言選項
    if (
      title === "Chin Shi Huang" ||
      title === "Crazy 777" ||
      title === "Fortune Gems 2"
    ) {
      return typeThreeLanguages;
    } else if (type === "Crash") {
      //如果type等於Crash 就返回typeTwoLanguages
      return typeTwoLanguages;
    } else {
      // 預設使用 typeOneLanguages
      return typeOneLanguages;
    }
  };

  return (
    <section className="flex flex-col w-full min-h-[100vh] gap-6 px-6 md:px-24 py-10 pt-12">
      <Iframe Demolink={Demolink} direction={direction} />
      {/*這個是iframe的組件 傳入了Demolink和direction的props */}
      <div className="mt-2">
        <h1 className="text-light-black text-2xl md:text-4xl font-bold">
          {i18n_game(title)}
        </h1>
        {Array.isArray(intro) && intro.length > 0 ? (
          <ul className="text-light-gray font-normal my-4 list-disc pl-5 space-y-1">
            {intro.map((item, index) => (
              <li key={index}>{i18n_game(item)}</li>
            ))}
          </ul>
        ) : intro ? (
          <Text my={2} className="text-light-gray font-normal">
            {i18n_game(intro)}
          </Text>
        ) : null}
      </div>
      <div></div>
      <section className="w-full max-w-[500px]">
        <GameInfo />
        <div className="grid grid-cols-2 items-center text-light-gray text-base font-medium border-b border-light-gray py-3">
          <span>{i18n("Language")}</span>
          <span>
            <Select
              value={selectedLanguage}
              style={{ width: 180 }}
              onChange={handleLanguageChange}
            >
              {renderLanguageOptions().map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </span>
        </div>
      </section>
      {/* <section className="w-full max-w-[500px]">
        {infoItems.map((item, index) => (
          <div
            key={index}
            className="grid grid-cols-2 items-center text-light-gray text-base font-medium border-b border-light-gray py-3"
          >
            <span>{i18n(item.label)}</span>
            <span>{item.value}</span>
          </div>
        ))}
      </section> */}
    </section>
  );
}
export default PlayGame;
