import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link } from "react-router-dom";
import { carouselData } from "../helpers/carouselBannerList";
import { gameData } from "../helpers/gamelist";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_ALT } from "../constant";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import StackedCarousel from "./StackedCarousel";

const CarouselV2 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`carousel.${key}`);

  // 根據斷點決定使用哪種輪播類型 base: true (移動端使用堆疊輪播), md: false (中等屏幕以上使用 Splide 輪播)
  const shouldUseStacked = useBreakpointValue({ base: true, md: false });

  return shouldUseStacked ? ( // 根據螢幕大小渲染不同的輪播組件
    <StackedCarousel carouselData={carouselData} /> // 移動端渲染堆疊卡片式輪播
  ) : (
    <SplideCarousel carouselData={carouselData} /> // 桌面端渲染傳統幻燈片輪播
  );
};

const SplideCarousel = ({ carouselData }) => {
  const slideType = useBreakpointValue({ base: "loop", md: "loop" }); // 根據斷點決定輪播類型

  return (
    <Flex
      justifyContent={"center"}
      px={{ base: 3, md: 4, lg: "26px" }}
      pt={{ base: 3, md: 4, lg: 8 }}
      pb={{ base: "20px", md: 4, lg: 8 }}
      position="relative"
    >
      <Splide
        className="h-[85vw] md:h-[31vw] lg:h-[340px] w-full max-w-[1680px] overflow-hidden rounded-[52px] sm:menu-box-shadow"
        options={{
          type: slideType,
          perPage: 1,
          gap: 0,
          autoplay: false,
          arrows: true,
          perMove: 1,
          focus: "center",
          trimSpace: false,
          padding: { base: "10%", md: 0 },
          breakpoints: {
            768: {
              padding: "8%",
              gap: 10,
            },
          },
        }}
      >
        {carouselData.map((game, index) => (
          <SplideSlide key={index} className="relative">
            <LazyLoadImage
              alt={game.title}
              src={game.image}
              data-splide-lazy="path-to-the-image"
              className="object-cover w-full h-[143vw] md:h-[31vw] lg:h-[340px] relative hidden md:block"
            />
            <LazyLoadImage
              alt={game.title}
              src={game.imageMobile}
              data-splide-lazy="path-to-the-image"
              className="object-cover w-full h-[85vw] md:h-[31vw] lg:h-[340px] relative block md:hidden rounded-[52px]"
            />
          </SplideSlide>
        ))}
      </Splide>
    </Flex>
  );
};

export default CarouselV2;
