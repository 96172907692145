import React from "react";
import Contact from "../../components/Contact";
import ContactV3 from "../../components/Contact_v3";
import { calc, Flex } from "@chakra-ui/react";

const ContactPage = () => {
  return (
    <Flex
      flexDirection={"column"}
      w={"100%"}
      minH={"calc(100vh - 111px)"}
      gap={6}
      p={{ base: 4, md: 12 }}
    >
      <ContactV3 />
    </Flex>
  );
};

export default ContactPage;
