import React from "react";
import { stylesConfig } from "../helpers/stylesConfig";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";

import footerIcon1 from "../images/footer/sigma.png";
import footerIcon2 from "../images/footer/bmm.png";
import footerIcon3 from "../images/footer/antillephone.png";
import footerIcon4 from "../images/footer/gli.png";
import footerIcon5 from "../images/footer/itech.png";
import { Link } from "react-router-dom";
import { IoMail, IoMailOutline } from "react-icons/io5";
import { BiLogoTelegram } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { PiTelegramLogoLight } from "react-icons/pi";
import ShareButton from "./ShareButton";
import { COMMON_ALT } from "../constant";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { IoIosMail } from "react-icons/io";
import {
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);

  const socialLinks = [
    { to: "#", icon: <FaFacebookF className="text-xl text-white m-1" /> },
    { to: "#", icon: <FaXTwitter className="text-xl text-white m-1" /> },
    { to: "#", icon: <FaYoutube className="text-xl text-white m-1" /> },
    { to: "#", icon: <FaInstagram className="text-xl text-white m-1" /> },
    { to: "#", icon: <BiLogoTelegram className="text-xl text-white m-1" /> },
  ];
  const informationLinks = [
    { to: "#", text: "About Us" },
    { to: "#", text: "Privacy Policy" },
    { to: "#", text: "Terms & Conditions" },
    { to: "#", text: "Responsible Gaming" },
  ];
  const gameLinks = [
    { to: "#", text: "Slot" },
    { to: "#", text: "Casino" },
    { to: "#", text: "Bingo" },
    { to: "#", text: "Lottery" },
  ];
  const serviceLinks = [
    { to: "#", text: "Blog" },
    { to: "#", text: "Blog" },
    { to: "#", text: "Blog" },
    { to: "#", text: "Blog" },
  ];

  return (
    <Flex
      display={{ base: "none", md: "flex" }}
      paddingX={{ base: "4", md: "16" }}
      paddingY={"4"}
      textAlign={"center"}
      className=" bg-white border-t-2 border-t-base-white"
      position={"relative"}
      zIndex={"2"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        w={"100%"}
        marginX={"auto"}
        paddingY={"4"}
        paddingBottom={"8"}
        className="box-border block"
      >
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          gap={4}
          justifyContent={"space-between"}
          alignItems={{ base: "flex-start", md: "flex-start" }}
          paddingX={{ base: "12%", md: "0" }}
        >
          <Flex
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            gap={2}
          >
            <Link to="/">
              {/* <Text
                className="font-custom text-xl font-extrabold italic cursor-pointer"
                color={"black"}
              >
                WSKY
              </Text> */}
              <Image
                alt={COMMON_ALT}
                src={stylesConfig.LogoBlack}
                w={"150px"}
                marginRight={"8"}
                className="logo-drop-shadow"
              />
            </Link>
            <Text className="text-black text-lg font-bold">
              {i18n("Get our exclusive offer!")}
            </Text>
            <Box marginBottom={"2"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  style={{ fontSize: "20px" }}
                >
                  <IoIosMail color="black" />
                </InputLeftElement>
                <Input
                  type="email"
                  name="email"
                  placeholder={i18n("Email")}
                  className="text-light-black"
                  borderWidth="0 0 1px 0"
                  borderColor="black"
                  borderRadius="0"
                  _focus={{
                    borderColor: "black",
                    boxShadow: "none",
                  }}
                />
              </InputGroup>
              {/* <Input
                type="email"
                name="email"
                className="inline-flex items-center input-border cursor-text p-3 text-text-second-gray bg-[#ffd5ce]"
                size="large"
                variant="flushed"
                placeholder={i18n("Email")}
                prefix={<IoMail className="text-black text-2xl mr-2" />}
              /> */}
            </Box>
            <Button
              type="submit"
              htmlType="submit"
              className="submit-button rounded-[5px] !px-[15px] !py-[6px] !h-auto !font-medium"
            >
              {i18n("Submit")}
            </Button>
          </Flex>
          <Flex
            display={{ base: "none", md: "flex" }}
            flexDirection={"column"}
            gap={2}
            justifyContent={"center"}
            alignItems={"flex-start"}
          >
            {/* <Text className="text-black text-lg font-bold">
              {i18n("Contact Us")}
            </Text> */}
            {informationLinks.map((link, index) => (
              <Link key={index} to={link.to} target="_blank">
                <Text className="text-light-gray-3 hover:text-fifth-color">
                  {link.text}
                </Text>
              </Link>
            ))}
          </Flex>
          <Flex
            display={{ base: "none", md: "flex" }}
            flexDirection={"column"}
            gap={2}
            justifyContent={"center"}
            alignItems={"flex-start"}
          >
            {/* <Text className="text-black text-lg font-bold">
              {i18n("Contact Us")}
            </Text> */}
            {gameLinks.map((link, index) => (
              <Link key={index} to={link.to} target="_blank">
                <Text className="text-light-gray-3 hover:text-fifth-color">
                  {link.text}
                </Text>
              </Link>
            ))}
          </Flex>
          <Flex
            display={{ base: "none", md: "flex" }}
            flexDirection={"column"}
            gap={2}
            justifyContent={"center"}
            alignItems={"flex-start"}
          >
            {/* <Text className="text-black text-lg font-bold">
              {i18n("Contact Us")}
            </Text> */}
            {serviceLinks.map((link, index) => (
              <Link key={index} to={link.to} target="_blank">
                <Text className="text-light-gray-3 hover:text-fifth-color">
                  {link.text}
                </Text>
              </Link>
            ))}
          </Flex>
          <Flex
            flexDirection={"column"}
            gap={4}
            justifyContent={"center"}
            alignItems={"flex-start"}
          >
            <Text className="text-black text-lg font-bold">
              {i18n("Contact Us")}
            </Text>
            <Flex flexDirection={"column"} gap={2}>
              {/* <Flex justifyContent={"flex-start"} alignItems={"center"} gap={3}>
                <FaPhoneAlt className="text-main-color" fontSize={"xl"} />
                <Text className="text-light-gray-3">+886 123456789</Text>
              </Flex> */}
              <Flex justifyContent={"flex-start"} alignItems={"center"} gap={3}>
                <IoMail className="text-main-color" fontSize={"xl"} />
                <Text className="text-light-gray-3">wenhao@worthysky.com</Text>
              </Flex>
            </Flex>

            <Flex justifyContent={"center"} alignItems={"center"} gap={3}>
              {socialLinks.map((link, index) => (
                <Link
                  key={index}
                  to={link.to}
                  target="_blank"
                  className=" bg-black hover:bg-fifth-color rounded-[50%]  flex justify-center items-center"
                >
                  {link.icon}
                </Link>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box
        paddingX={{ base: "4", md: "16" }}
        paddingTop={"4"}
        w={"80%"}
        textAlign={"center"}
        className=" border-t-2 border-t-gray-line"
        color={"black"}
      >
        {i18n("allRightsReserved")}
      </Box>
    </Flex>
  );
};

export default Footer;
