import React, { useContext, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { stylesConfig } from "../../../helpers/stylesConfig";
import MenuItem from "./MenuItem";
import LanguageSettings from "../../components/LanguageSettings";
import MenuMobile from "../../../components/MenuMobile";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { ContactScrollContext } from "../../../store/triggerScrollToContact";
import { COMMON_ALT } from "../../../constant";
import SearchButton from "../SearchButton";
import { Box, Flex, Text } from "@chakra-ui/react";
import SearchButtonV2 from "../SearchButton_v2";

const MenuTop = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = useMemo(() => {
    if (location.pathname === "/") {
      return true;
    } else {
      return false;
    }
  }, [location.pathname]);

  const scrollCtx = useContext(ContactScrollContext);
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} gap={4}>
      <Link to="/">
        {/* <Text
          className="font-custom text-5xl font-extrabold italic cursor-pointer my-2"
          color={{
            base: "#48D498",
            md: "white",
          }}
        >
          WSKY
        </Text> */}
        <img
          alt={COMMON_ALT}
          src={stylesConfig.LogoBlack}
          className="w-[100px] cursor-pointer mobile_show"
        />
        <img
          alt={COMMON_ALT}
          src={stylesConfig.headerLogo}
          className=" cursor-pointer pc_show"
          style={{
            width: stylesConfig.headerLogoWidth,
            height: stylesConfig.headerLogoHeight,
            maxHeight: "70px",
          }}
        />
      </Link>
      {/* <div className="block md:hidden w-[58%]">
        <SearchButton />
      </div> */}

      <Box className="hidden md:flex justify-between items-center gap-2">
        <SearchButtonV2 />
        <Box className="hidden md:flex justify-between items-center">
          <Box className="pc_show">
            <LanguageSettings />
          </Box>
          {/* <div
            onClick={() => {
              if (isHomePage) {
                scrollCtx.trigger();
              } else {
                navigate("/");
                scrollCtx.trigger();
              }
            }}
            className="gradient-button text-white cursor-pointer text-xs md:text-base font-bold blue-button px-3 md:px-5 py-1.5 md:py-2.5 rounded-[12px] hover-bg-transition"
          >
            {i18n("Contact")}
          </div> */}
        </Box>
        <MenuItem />
      </Box>
      <MenuMobile />
    </Flex>
  );
};

export default MenuTop;
