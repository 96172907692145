import { min } from "date-fns";
import { COMMON_WEB_PATH } from "./stylesConfig";

// 圖片路徑會根據當前語言動態生成
const getImagePath = (lang = "en") =>
  `${COMMON_WEB_PATH}/WSKYgameSample/gamePic/${lang}`;
const getImagePath_mobile = (lang = "en") =>
  `${COMMON_WEB_PATH}/WSKYgameSample/gamePic/${lang}`;

export const gameData = (currentLang = "en") => {
  const GAME_PATH = getImagePath(currentLang);
  const GAME_MOBILE_PATH = getImagePath_mobile(currentLang);

  return [
    {
      title: "Dragon Ball",
      new: true,
      uid: "SL2445",
      direction: "vertical",
      image: `${GAME_PATH}/SL2445.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2445.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/sl2445/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "15,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "免費遊戲模式，初始獲得 12 次免費旋轉。",
        "免費遊戲期間，每次旋轉都會增加獎金倍數。",
        "支援加碼投注，增加獲勝和觸發免費遊戲功能的機會。",
        "支援購買免費遊戲。",
      ],
      minBet: "1",
      maxBet: "100",
    },
    {
      title: "Naruto",
      new: true,
      uid: "SL2446",
      direction: "vertical",
      image: `${GAME_PATH}/SL2446.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2446.webp`,
      tag: "Top",
      Demolink: "https://game.helloholyfa.com/slot/SL2446/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "15,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "免費遊戲模式，初始獲得 12 次免費旋轉。",
        "免費遊戲期間，每次旋轉都會增加獎金倍數。",
        "支援加碼投注，增加獲勝和觸發免費遊戲功能的機會。",
        "支援購買免費遊戲。",
      ],
      minBet: "1",
      maxBet: "100",
    },
    {
      title: "Pinatas Locas",
      new: true,
      uid: "SL2447",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2447.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2447.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2447/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "13,350 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "神秘符號，有機會揭開累積符號。",
        "3 種免費遊戲特色玩法：累積免費遊戲次數、累積彩金獎勵、消除符號增加連線機率。",
      ],
      minBet: "1",
      maxBet: "100",
    },
    {
      title: "Panther Moon",
      new: true,
      uid: "SL2448",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2448.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2448.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2448/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "10,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "免費遊戲模式，初始獲得 15 次免費旋轉及 3 倍的倍數獎勵值。",
        "免費遊戲期間，所有獎勵乘上 3 倍。",
      ],
      minBet: "3",
      maxBet: "90",
    },
    {
      title: "White King",
      new: true,
      uid: "SL2449",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2449.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2449.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2449/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "1,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "免費遊戲模式，初始獲得 8 次免費旋轉。",
        "免費遊戲期間，增加百搭符號出現機率、提高連線機會。",
        "百搭符號堆疊出現。",
      ],
      minBet: "8",
      maxBet: "240",
    },
    {
      title: "Triple Twister",
      new: true,
      uid: "SL2450",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2450.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2450.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2450/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "9,999 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "免費遊戲模式，初始獲得 9 次免費旋轉。",
        "贏獎組合包含百搭符號，獎勵乘上 3 倍。",
        "擴展百搭，增加贏獎機率。",
      ],
      minBet: "10",
      maxBet: "300",
    },
    {
      title: "T-Rex",
      new: true,
      uid: "SL2451",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2451.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2451.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2451/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "10,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "免費遊戲模式，可選擇免費遊戲次數、最高 30 次。",
        "免費遊戲期間，百搭有機取代所有高倍率符號。",
        " 百搭符號出現於任合贏獎組合，獎勵加倍。",
      ],
      minBet: "5",
      maxBet: "150",
    },
    {
      title: "Richy Piggy",
      new: true,
      uid: "SL2453",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2453.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2453.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2453/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "2,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "每次旋轉都有機會觸發重轉特色遊戲。",
        "重轉特色功能，第 2 輪將被百搭符號覆蓋並鎖定，直到轉出中獎組合。",
        "有機會獲得全盤大獎。",
      ],
      minBet: "1",
      maxBet: "100",
    },
    {
      title: "Break-In",
      new: true,
      uid: "SL2454",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2454.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2454.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2454/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "3,333 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "特殊紅利轉輪，提供多種特色獎勵。",
        "有機會觸發重轉機會，並隨機獲得 1~5 次的重轉次數。",
      ],
      minBet: "3",
      maxBet: "90",
    },
    {
      title: "AzteCash",
      new: true,
      uid: "SL2455",
      direction: "horizontal",
      image: `${GAME_PATH}/SL2455.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2455.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/slot/SL2455/",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "5,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "免費遊戲模式，初始獲得 15 次免費旋轉。",
        "消除類型遊戲，任一位置獲獎組合均可賠付。",
        "有機會出現贏分加倍符號，獲得高額獎勵。",
      ],
      minBet: "1",
      maxBet: "90",
    },
    {
      title: "Rocket",
      new: true,
      uid: "CR2401",
      direction: "vertical",
      image: `${GAME_PATH}/CR2401.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/CR2401.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/crash/cr2401/",
      Morelink: "/products",
      type: "Crash",
      maxOdds: "10,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "多人同時在線遊玩。",
        "每輪遊戲可選擇押 1 或 2 注。",
        "自由決定您的取分時機。",
        "可驗證遊戲結果。",
      ],
      minBet: "5",
      maxBet: "20,000",
    },
    {
      title: "Plinko Cosmos",
      new: true,
      uid: "CR2402",
      direction: "vertical",
      image: `${GAME_PATH}/CR2402.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/CR2402.webp`,
      // tag: "New",
      Demolink: "https://game.helloholyfa.com/crash/cr2402/",
      Morelink: "/products",
      type: "Crash",
      maxOdds: "1,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "可選擇您想要遊玩的風險等級，共 3 種等級。",
        "可選擇出球數量，最多 100 顆。",
        "可驗證遊戲結果。",
      ],
      minBet: "5",
      maxBet: "20,000",
    },
    {
      title: "Zone",
      new: true,
      uid: "CR2403",
      direction: "vertical",
      image: `${GAME_PATH}/CR2403.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/CR2403.webp`,
      // tag: "New",
      Demolink: "https://game.helloholyfa.com/crash/cr2403/",
      Morelink: "/products",
      type: "Crash",
      maxOdds: "50 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "可選擇您想要遊玩的風險等級，共 3 種等級。",
        "可設定輪盤賠率區段。",
        "可驗證遊戲結果。",
      ],
      minBet: "5",
      maxBet: "20,000",
    },
    {
      title: "HiLo",
      new: true,
      uid: "CR2404",
      direction: "vertical",
      image: `${GAME_PATH}/CR2404.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/CR2404.webp`,
      // tag: "New",
      Demolink: "https://game.helloholyfa.com/crash/cr2404/",
      Morelink: "/products",
      type: "Crash",
      maxOdds: "10,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "簡易的卡牌遊戲，預測抽出的牌和前一張相比的大小。",
        "獲勝後可選擇兌現或進行連贏挑戰。",
        "可驗證遊戲結果。",
      ],
      minBet: "5",
      maxBet: "20,000",
    },
    {
      title: "Dice or Die",
      new: true,
      uid: "CR2405",
      direction: "vertical",
      image: `${GAME_PATH}/CR2405.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/CR2405.webp`,
      // tag: "New",
      Demolink: "https://game.helloholyfa.com/crash/cr2405/",
      Morelink: "/products",
      type: "Crash",
      maxOdds: "10,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "刺激的骰盅遊戲，預測點數總和。",
        "若預測成功，可進行獎金翻倍遊戲。",
        "支援購買免費遊戲。",
      ],
      minBet: "5",
      maxBet: "20,000",
    },
    {
      title: "Fly to Win",
      new: true,
      uid: "CR2406",
      direction: "vertical",
      image: `${GAME_PATH}/CR2406.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/CR2406.webp`,
      // tag: "New",
      Demolink: "https://game.helloholyfa.com/crash/cr2406/",
      Morelink: "/products",
      type: "Crash",
      maxOdds: "5,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "多人同時在線遊玩。",
        "每輪遊戲可選擇押 1 或 2 注。",
        "自由決定您的取分時機。",
        "可驗證遊戲結果。",
      ],
      minBet: "5",
      maxBet: "20,000",
    },
    {
      title: "Rainbow Golds",
      new: true,
      uid: "CR2407",
      direction: "vertical",
      image: `${GAME_PATH}/CR2407.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/CR2407.webp`,
      tag: "New",
      Demolink: "https://game.helloholyfa.com/crash/cr2407/",
      Morelink: "/products",
      type: "Crash",
      maxOdds: "5,000 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro: [
        "彩虹金幣不停落下，目標在最多金幣落入有效區域時暫停，以獲得最高獎勵。",
        "金幣掉落期間，有機會出現寶箱，寶箱內藏有加倍倍數。",
        "可驗證遊戲結果。",
      ],
      minBet: "5",
      maxBet: "20,000",
    },
    {
      title: "Chin Shi Huang",
      new: true,
      uid: "SL2460",
      direction: "vertical",
      image: `${GAME_PATH}/SL2460.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2460.webp`,
      tag: "New",
      Demolink:
        "https://game.helloholyfa.com/slot/sl2460/?lang=en&allowRotation=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "17,200 X",
      RTP: "96%",
      Resolution: "720x1680",
      DemoSiteRTP: "96%",
      intro: [
        "4096 種連線方式，暢快遊玩。",
        "分散符號輕鬆中獎，出現即獲獎勵。",
        "免費遊戲加倍樂趣，局數與獎倍率同步提升。",
      ],
      minBet: "0.5",
      maxBet: "1,000",
    },
    {
      title: "Crazy 777",
      new: true,
      uid: "SL2461",
      direction: "vertical",
      image: `${GAME_PATH}/SL2460.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2461.webp`,
      tag: "New",
      Demolink:
        "https://game.helloholyfa.com/slot/sl2461/?lang=en&allowRotation=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "3,333 X",
      RTP: "96%",
      Resolution: "720x1680",
      DemoSiteRTP: "96%",
      intro: [
        "特殊轉輪可獲得各種特色獎勵或重轉遊戲。",
        "觸發特殊重轉遊戲，獲得 1 ~ 5 次重轉中獎組合。",
      ],
      minBet: "0.6",
      maxBet: "1,920",
    },
    {
      title: "Fortune Gems 2",
      new: true,
      uid: "SL2462",
      direction: "vertical",
      image: `${GAME_PATH}/SL2460.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/SL2462.webp`,
      tag: "New",
      Demolink:
        "https://game.helloholyfa.com/slot/sl2462/?lang=en&allowRotation=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "15,000 X",
      RTP: "96%",
      Resolution: "720x1680",
      DemoSiteRTP: "96%",
      intro: [
        "幸運轉輪必定中獎。",
        "額外押注功能，大幅增加高乘倍符號獲得機會。",
      ],
      minBet: "1",
      maxBet: "1,000",
    },
    // {
    //   title: "Chin Shi Huang",
    //   new: true,
    //   uid: "SL2460",
    //   direction: "horizontal",
    //   image: `${GAME_PATH}/SL2460.webp`,
    //   image_mobile: `${GAME_MOBILE_PATH}/SL2460.webp`,
    //   tag: "New",
    //   Demolink:
    //     "https://game.helloholyfa.com/slot/sl2460/?lang=en&allowRotation=true",
    //   Morelink: "/products",
    //   type: "Slot",
    //   maxOdds: "17,200 X",
    //   RTP: "96%",
    //   Resolution: "720x1680",
    //   DemoSiteRTP: "97%",
    //   intro: "",
    //   minBet: "0.5",
    //   maxBet: "1000",
    // },
    // {
    //   title: "Crazy 777",
    //   new: true,
    //   uid: "SL2461",
    //   direction: "horizontal",
    //   image: `${GAME_PATH}/SL2461.webp`,
    //   image_mobile: `${GAME_MOBILE_PATH}/SL2461.webp`,
    //   tag: "New",
    //   Demolink:
    //     "https://game.helloholyfa.com/slot/sl2461/?lang=en&allowRotation=true",
    //   Morelink: "/products",
    //   type: "Slot",
    //   maxOdds: "3,333 X",
    //   RTP: "96%",
    //   Resolution: "720x1680",
    //   DemoSiteRTP: "97%",
    //   intro: "",
    //   minBet: "0.6",
    //   maxBet: "1920",
    // },
    // {
    //   title: "Fortune Gems 2",
    //   new: true,
    //   uid: "SL2462",
    //   direction: "horizontal",
    //   image: `${GAME_PATH}/SL2462.webp`,
    //   image_mobile: `${GAME_MOBILE_PATH}/SL2462.webp`,
    //   tag: "New",
    //   Demolink:
    //     "https://game.helloholyfa.com/slot/sl2462/?lang=en&allowRotation=true",
    //   Morelink: "/products",
    //   type: "Slot",
    //   maxOdds: "15,000 X",
    //   RTP: "96%",
    //   Resolution: "720x1680",
    //   DemoSiteRTP: "97%",
    //   intro: "",
    //   minBet: "1",
    //   maxBet: "1000",
    // },
  ];
};
