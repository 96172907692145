import React from "react";
import { Helmet } from "react-helmet";
import { stylesConfig } from "../../helpers/stylesConfig";
import useWebsiteInfo from "../../hooks/useWebsiteInfo";

const WebHelmet = () => {
  const { is911vip, is5285 } = useWebsiteInfo();

  // 根據網址設置不同的標題和描述
  let title = "WSKY Gaming";
  let description =
    "WSKY Gaming was created by experienced and passionate industry experts, and we develop top-notch slot games for the global audience. We also provide fishing games, offering a rich variety of gaming options for online entertainment operators.";

  if (is911vip) {
    title = "911VIP Gaming";
    description =
      "911VIP Gaming offers top-notch slot games and fishing games for the global audience, providing a rich variety of gaming options for online entertainment operators.";
  } else if (is5285) {
    title = "5285 Gaming";
    description =
      "5285 Gaming provides high-quality slot games and fishing games developed by experienced industry experts, offering diverse gaming options for online entertainment.";
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta content="website" property="og:type" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={stylesConfig.mainLogo} />
      <meta
        property="og:url"
        content={
          is911vip
            ? "https://911vip.co/"
            : is5285
            ? "https://5285.com.tw/"
            : "https://mkvip.co/"
        }
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=0"
      ></meta>
      <link rel="icon" href={stylesConfig.LogoBlack} />
    </Helmet>
  );
};

export default WebHelmet;
