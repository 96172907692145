import React, { useState } from "react";

const Iframe = ({ Demolink, direction }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const iframeHeight = isFullScreen
    ? "h-[calc(100%-35px)] rounded-none"
    : direction === "vertical"
    ? "h-[580px] md:h-[550px] rounded-t-xl"
    : "h-[320px] md:h-[450px] rounded-t-xl";

  return (
    <div
      className={`${
        isFullScreen ? "fixed top-0 left-0 w-screen h-full z-[99]" : ""
      }`}
    >
      <iframe
        src={Demolink}
        className={`${iframeHeight} w-full bg-[#272727]`}
        title="Game Demo"
        allowFullScreen={true}
        allow="autoplay; fullscreen; encrypted-media; picture-in-picture; web-share"
      />
      <div
        className={`flex items-center justify-end p-4 h-[35px] bg-black text-text-second-gray ${
          isFullScreen ? "" : "rounded-b-xl"
        }`}
      >
        {/* <p className="font-semibold text-light-gray hover:text-main-color cursor-pointer">
          BACK
        </p> */}
        <p
          className="font-semibold text-light-gray hover:text-main-color cursor-pointer"
          onClick={() => {
            setIsFullScreen(!isFullScreen);
          }}
        >
          {isFullScreen ? "EXIT" : "FULL"}
        </p>
      </div>
    </div>
  );
};

export default Iframe;
