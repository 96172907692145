export const languageMapping = (language) => {
  switch (language) {
    case "tw":
      return "繁體";
    case "zh_chs":
      return "简体";
    case "en":
      return "English";
    case "th":
      return "อักษรไทย";
    case "viet":
      return "Tiếng Việt";
    case "id":
      return "Indonesia";
  }
};

export const languageOptions = [
  { value: "en", label: "English", countryCode: "US" },
  { value: "tw", label: "繁體中文", countryCode: "TW" },
  { value: "zh_chs", label: "简体中文", countryCode: "CN" },
  { value: "th", label: "อักษรไทย", countryCode: "TH" },
  { value: "viet", label: "Tiếng Việt", countryCode: "VN" },
  { value: "id", label: "Indonesia", countryCode: "ID" },
];
