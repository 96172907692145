export const userLanguage = () => {
  const lang = window.navigator.userLanguage || window.navigator.language;
  const defaultLang = lang.toLowerCase();
  if (defaultLang === "zh-cn" || defaultLang === "zh-tw") {
    return "CN";
  } else if (defaultLang === "th") {
    return "TH";
  } else if (defaultLang === "vi") {
    return "VN";
  } else if (defaultLang === "id") {
    return "ID";
  } else {
    return "EN";
  }
};
