import React from "react";
import { IconContext } from "react-icons";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { Box, Flex } from "@chakra-ui/react";

const WrapperTitle = ({ title, icon }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  return (
    <Flex alignItems={"center"}>
      <span className="text-main-color text-xl mr-2 md:mr-4"> {icon}</span>
      <h2 className="text-black font-black text-xl md:text-3xl">{title}</h2>
    </Flex>
  );
};

export default WrapperTitle;
