// FavoriteButton.jsx
import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

const FavoriteButton = ({ gameId, initialState = false }) => {
  const [isFavorite, setIsFavorite] = useState(initialState);

  const toggleFavorite = (e) => {
    e.preventDefault(); // 防止事件冒泡
    e.stopPropagation();
    setIsFavorite(!isFavorite);
  };

  return (
    <Box
      position="absolute"
      top={2}
      right={2}
      zIndex={2}
      cursor="pointer"
      onClick={toggleFavorite}
      className="transition-transform duration-200 hover:scale-110"
      backgroundColor={"#fff"}
      rounded={"full"}
      padding={2}
    >
      {isFavorite ? (
        <AiFillHeart size={14} className="text-red-500" />
      ) : (
        <AiOutlineHeart size={14} className="text-light-gray-3" />
      )}
    </Box>
  );
};

export default FavoriteButton;
