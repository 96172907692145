import React from "react";
import MenuTop from "./MenuBar/MenuTop";
import Sidebar from "./Sidebar";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import LevelUpV3 from "../../components/LevelUp_v3";
import TopGames from "../../components/TopGames";
import { Box, Flex } from "@chakra-ui/react";

const MainLayout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <Box className="md:min-h-screen">
      {/* Top Menu Bar */}
      <Box
        className="border-b-0 md:border-b-2 border-b-base-white px-5 md:px-4 lg:px-[68px] py-5 md:py-4 fixed top-0 w-full z-10"
        style={{
          background:
            window.innerWidth >= 768
              ? "transparent linear-gradient(90deg, #48D498 0%, #6FECBD 100%) 0% 0% no-repeat padding-box"
              : "white",
        }}
      >
        <MenuTop />
      </Box>

      <Flex className="pt-[111px] md:pt-[81px] lg:pt-[81px]">
        {/*左邊*/}
        <Box className="hidden md:block w-[240px] fixed left-0 top-[81px] bottom-0 overflow-y-auto">
          <Sidebar />
        </Box>

        <Flex className="w-full md:w-[calc(100%-240px)] md:ml-[240px]">
          {/*中間*/}
          <div className="w-full md:w-[calc(100%-240px)]">{children}</div>

          {/*右側*/}
          <Box className="hidden md:block w-[240px]">
            <TopGames />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MainLayout;
