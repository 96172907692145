import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { COMMON_WEB_PATH, typeIconConfig } from "../helpers/stylesConfig";
import { Box, Flex, Image } from "@chakra-ui/react";

const additionalLinks = [
  {
    id: "favorite",
    icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/favorite-w.svg`,
    hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/favorite.svg`,
    path: "/favorite",
    title: "Favorite",
    position: "end",
  },
  {
    id: "contact",
    icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/contact-w.svg`,
    hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/contact.svg`,
    path: "/contact",
    title: "Contact",
    position: "end",
  },
  {
    id: "about",
    icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/about-w.svg`,
    hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/about.svg`,
    path: "/about",
    title: "About",
    position: "end",
  },
];

const MenuContentInnerV2 = ({ onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const location = useLocation();

  // 只過濾需要的連結
  const endLinks = additionalLinks.filter((link) => link.position === "end");

  // 切換分類
  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  // 檢查活動連結
  const isActive = (path, category = null) => {
    if (category) return location.pathname === `/products/${category}`;
    return path === "/"
      ? location.pathname === path
      : location.pathname.startsWith(path);
  };

  // 取icon
  const getTypeIcon = (type, isWhite = false) => {
    const iconPath = typeIconConfig[type];
    return iconPath
      ? isWhite
        ? iconPath.replace(".svg", "-w.svg")
        : iconPath
      : "";
  };

  // 連結
  const renderLink = (item, isGameCategory = false) => {
    const active = isActive(item.path, isGameCategory ? item : null);
    const linkPath = isGameCategory ? `/products/${item}` : item.path;

    // 常用的類名
    const baseIconClass =
      "w-6 h-6 absolute top-0 left-0 transition-opacity duration-200";
    const activeTextClass = active
      ? "text-main-color"
      : "text-white md:text-black group-hover:text-white";

    return (
      <Link
        key={item.id || item}
        to={linkPath}
        onClick={onClose} // 關閉菜單功能
        className={`group relative z-[2] flex items-center justify-between w-full rounded-[14px] px-4 py-3 transition-colors duration-200 
          ${active ? "bg-base-light-white2" : "hover:bg-second-color"}`}
      >
        <Flex alignItems="center" gap={{ base: 4, md: 6 }}>
          {isGameCategory ? (
            <div className="w-6 h-6 relative">
              <Image
                src={getTypeIcon(item)}
                alt={item}
                className={`${baseIconClass} ${
                  active ? "opacity-0" : "group-hover:opacity-0"
                }`}
              />
              <Image
                src={getTypeIcon(item, true)}
                alt={item}
                className={`${baseIconClass} ${
                  active ? "opacity-100" : "opacity-0 group-hover:opacity-100"
                }`}
              />
            </div>
          ) : (
            <Box className="w-6 h-6 relative">
              <Image
                src={item.icon}
                alt={item.title}
                className={`${baseIconClass} ${
                  active ? "opacity-0" : "group-hover:opacity-0"
                }`}
              />
              <Image
                src={item.hoverIcon}
                alt={item.title}
                className={`${baseIconClass} ${
                  active ? "opacity-100" : "opacity-0 group-hover:opacity-100"
                }`}
              />
            </Box>
          )}
          <span
            className={`text-2xl font-medium transition-colors duration-200 ${activeTextClass}`}
          >
            {isGameCategory ? i18n(item) : i18n(item.title.toLowerCase())}
          </span>
        </Flex>
      </Link>
    );
  };

  return (
    <Flex flexDirection="column" w="100%" gap={2} mt={6}>
      {endLinks.map((link) => renderLink(link))}
    </Flex>
  );
};

export default MenuContentInnerV2;
