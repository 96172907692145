import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { gameData } from "../../helpers/gamelist";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { COMMON_WEB_PATH, typeIconConfig } from "../../helpers/stylesConfig";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { Box, Flex, Image } from "@chakra-ui/react";

const GameCategoryListV2 = () => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const location = useLocation();
  const currentLang = i18nInstance.language || "en";

  // 獲取當前語言的遊戲數據
  const currentGameData = gameData(currentLang);

  // 新的圖片路徑配置
  const GAME_LIST_PATH = `${COMMON_WEB_PATH}/smallGameIcon/${currentLang}`;

  // 取得所有不重複的遊戲類型
  const gameCategories = [...new Set(currentGameData.map((game) => game.type))];
  //console.log(gameCategories);

  // 管理每個類別的展開狀態
  const [expandedCategory, setExpandedCategory] = useState(null);

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const additionalLinks = [
    {
      id: "lobby",
      icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/lobby.svg`,
      hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/lobby-w.svg`,
      path: "/",
      title: "Lobby",
      position: "start",
    },
    {
      id: "favorite",
      icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/favorite.svg`,
      hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/favorite-w.svg`,
      path: "/favorite",
      title: "Favorite",
      position: "end",
    },
    {
      id: "contact",
      icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/contact.svg`,
      hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/contact-w.svg`,
      path: "/contact",
      title: "Contact",
      position: "end",
    },
  ];

  const startLinks = additionalLinks.filter(
    (link) => link.position === "start"
  );
  const endLinks = additionalLinks.filter((link) => link.position === "end");

  const isActive = (path, category = null) => {
    if (category) {
      return location.pathname === `/products/${category}`;
    }
    if (path === "/") {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  const getTypeIcon = (type, isWhite = false) => {
    const iconPath = typeIconConfig[type];
    if (!iconPath) return "";
    return isWhite ? iconPath.replace(".svg", "-w.svg") : iconPath;
  };

  const renderLink = (item, isGameCategory = false) => {
    const active = isActive(item.path, isGameCategory ? item : null);
    const linkPath = isGameCategory ? `/products/${item}` : item.path;

    return (
      <Link
        key={item.id || item}
        to={linkPath}
        className={`group relative z-[2] flex items-center justify-between w-full rounded-[14px] px-4 py-3 transition-colors duration-200 
          ${active ? "bg-second-color" : "hover:bg-second-color"}`}
      >
        <Flex alignItems={"center"} gap={{ base: 2, md: 6 }}>
          {isGameCategory ? (
            <div className="w-6 h-6 relative">
              <Image
                src={getTypeIcon(item)}
                alt={item}
                className={`w-6 h-6 absolute top-0 left-0 transition-opacity duration-200 
                  ${active ? "opacity-0" : "group-hover:opacity-0"}`}
              />
              <Image
                src={getTypeIcon(item, true)}
                alt={item}
                className={`w-6 h-6 absolute top-0 left-0 transition-opacity duration-200 
                  ${
                    active ? "opacity-100" : "opacity-0 group-hover:opacity-100"
                  }`}
              />
            </div>
          ) : (
            <Box className="w-6 h-6 relative">
              <Image
                src={item.icon}
                alt={item.title}
                className={`w-6 h-6 absolute top-0 left-0 transition-opacity duration-200 
                  ${active ? "opacity-0" : "group-hover:opacity-0"}`}
              />
              <Image
                src={item.hoverIcon}
                alt={item.title}
                className={`w-6 h-6 absolute top-0 left-0 transition-opacity duration-200 
                  ${
                    active ? "opacity-100" : "opacity-0 group-hover:opacity-100"
                  }`}
              />
            </Box>
          )}
          <span
            className={`text-xl font-medium transition-colors duration-200 
            ${
              active
                ? "text-white"
                : "text-white md:text-black group-hover:text-white"
            }`}
          >
            {isGameCategory ? i18n(item) : i18n(item.title.toLowerCase())}
          </span>
        </Flex>
      </Link>
    );
  };

  return (
    <div className="flex flex-col w-full gap-2">
      {startLinks.map((link) => renderLink(link))}
      {gameCategories.map((category) => renderLink(category, true))}
      {endLinks.map((link) => renderLink(link))}
    </div>
  );
};

export default GameCategoryListV2;
