import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { gameData } from "../../helpers/gamelist";
import { Button, Form, Input } from "antd";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import { useParams } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { TbPokerChip } from "react-icons/tb";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaFish } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdArrowDropDown, MdCasino } from "react-icons/md";
import NewGameTag from "../../components/NewGameTag";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Image } from "semantic-ui-react";
import { typeIconConfig } from "../../helpers/stylesConfig";
import FavoriteButton from "../../components/FavoriteButton";
import GameTag from "../../components/GameTag";
import GameCard from "../../components/GameCard";
import GameCategories from "../../components/GameCategories";
import useWebsiteInfo from "../../hooks/useWebsiteInfo";

const Products = ({ game }) => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_game = (key) => t(`game.${key}`);
  const currentLang = i18nInstance.language || "en";

  const { is911vip, is5285 } = useWebsiteInfo();

  const [searchTerm, setSearchTerm] = useState(""); //這個state用來存搜索條件 預設為空

  const { type } = useParams();
  // console.log("type:", type);

  // 取當前語言的遊戲數據
  const currentGameData = useMemo(() => {
    return gameData(currentLang);
  }, [currentLang]);

  const GAME_LIST_FILTER = useMemo(() => {
    // 需要過濾的遊戲 UID
    const filteredGameUids = ["CR2401", "CR2404", "CR2405", "CR2406"];

    // 先根據網站過濾掉特定遊戲
    const websiteFilteredGames = currentGameData.filter(
      (game) => !(filteredGameUids.includes(game.uid) && (is911vip || is5285))
    );

    // 根據type過濾遊戲數據
    const filteredGames = websiteFilteredGames.filter((game) => {
      if (type) {
        return game.type === type;
      } else {
        return true; // 如果没有type参数，返回所有遊戲
      }
    });

    // 用搜尋條件再次過濾遊戲
    return filteredGames.filter((game) =>
      game.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, type, currentGameData, currentLang, is911vip, is5285]);

  const productItems = [
    {
      label: (
        <Link to="/products" className="text-base font-bold">
          {i18n("All Games")}
        </Link>
      ),
      key: "0",
      // icon: <IoGameController className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Slot" className="text-base font-bold">
          {i18n("Slot")}
        </Link>
      ),
      key: "1",
      // icon: <TbPokerChip className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Crash" className="text-base font-bold">
          {i18n_common("Crash")}
        </Link>
      ),
      key: "1",
      // icon: <TbPokerChip className="mr-2 !text-2xl" />,
      size: "large",
    },
    // {
    //   label: (
    //     <Link to="/products/Blockchain" className="text-base font-bold">
    //       {i18n("Blockchain")}
    //     </Link>
    //   ),
    //   key: "4",
    //   icon: <SiBlockchaindotcom className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
    // {
    //   label: (
    //     <Link to="/products/SICBO" className="text-base font-bold">
    //       {i18n("SICBO")}
    //     </Link>
    //   ),
    //   key: "3",
    //   icon: <MdCasino className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
    // {
    //   label: (
    //     <Link to="/products/Fish" className="text-base font-bold">
    //       {i18n("Fish")}
    //     </Link>
    //   ),
    //   key: "2",
    //   icon: <FaFish className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
  ];

  return (
    <>
      <GameCategories />
      <Flex
        flexDirection={"column"}
        w={"100%"}
        minH={"100vh"}
        gap={6}
        p={{ base: 4, md: 12 }}
      >
        <Flex
          flexDirection={"column"}
          textAlign={"left"}
          gap={2}
          display={{ base: "none", md: "flex" }}
        >
          <h1 className="text-black text-4xl md:text-5xl font-bold">
            {type ? i18n(type) : i18n("All Games")}
          </h1>
          <Text className="text-black text-2xl md:text-3xl font-normal mt-1">
            {i18n("Find out more about our games")}
          </Text>
        </Flex>
        <Box>
          <Flex
            justifyContent={"flex-start"}
            alignItems={"center"}
            marginBottom={6}
          >
            <Dropdown
              size="large"
              menu={{ items: productItems }}
              className="cursor-pointer"
              trigger={["hover"]}
            >
              <button onClick={(e) => e.preventDefault()}>
                <Space className="menu-font flex items-center justify-between min-w-[100px] bg-light-gray2 md:bg-fifth-color rounded-[15px] text-base-black md:text-base-light-white2 hover:text-main-color p-3 hover:bg-hover-mask-bg hover-bg-transition">
                  {type ? i18n(type) : i18n("Products")}
                  <MdArrowDropDown className="text-2xl" />
                </Space>
              </button>
            </Dropdown>
            <Input
              className="w-[50%] max-w-[500px] input-border cursor-text rounded-[15px] p-3 text-base-black md:text-light-gray bg-light-gray2 md:bg-fifth-color ml-1 md:ml-2"
              type="text"
              placeholder={i18n("Search Games")}
              size="large"
              value={searchTerm}
              onChange={(e) => {
                // 用onChange監聽input的值，並把值設成searchTerm
                setSearchTerm(e.target.value);
              }}
              prefix={
                <BsSearch className="text-base-black md:text-base-light-white2 text-2xl mr-2" />
              }
            />
          </Flex>
          <Flex justifyContent={"center"}>
            <Box
              display="grid"
              gridTemplateColumns={{
                base: "repeat(2, 1fr)", // 手機版
                sm: "repeat(2, 1fr)", // 平板
                md: "repeat(3, 1fr)", // 一般電腦
                lg: "repeat(3, 1fr)", // 大螢幕
                xl: "repeat(4, 1fr)", // 大螢幕
                // "2xl": "repeat(5, 1fr)",
                // "3xl": "repeat(6, 1fr)",
                // "4xl": "repeat(7, 1fr)",
              }}
              gap={4}
              w="100%"
            >
              {GAME_LIST_FILTER.length === 0 ? (
                <p className="text-light-gray font-semibold text-xl text-center">
                  {i18n("No games found")}.
                </p>
              ) : (
                GAME_LIST_FILTER.map((game, index) => (
                  <GameCard key={game.title} game={game} />
                  // <Box key={game.title} className="rounded-xl group">
                  //   <Box
                  //     className="gameSlide-container game-box-shadow transition-colors duration-200 group-hover:bg-second-color"
                  //     p={2}
                  //     rounded={"18px"}
                  //   >
                  //     <GameTag game={game} />
                  //     <Box position="relative">
                  //       <LazyLoadImage
                  //         src={game.image}
                  //         alt={game.title}
                  //         className="rounded-[18px] object-cover w-full h-[170px] sm:h-[200px]"
                  //         data-splide-lazy="path-to-the-image"
                  //       />
                  //       <FavoriteButton gameId={game.uid} />
                  //       <Box
                  //         position="absolute"
                  //         top={0}
                  //         left={0}
                  //         right={0}
                  //         bottom={0}
                  //         rounded={"18px"}
                  //         className="overlay opacity-0 group-hover:opacity-100 transition-opacity duration-200 bg-black/50 flex items-center justify-center"
                  //       >
                  //         <Box>
                  //           {/* <Text>{i18n(game.title)}</Text> */}
                  //           <Link to={`/play/${game.uid}`}>
                  //             <div className="text-white play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2">
                  //               {i18n("Play Demo")}
                  //             </div>
                  //           </Link>
                  //           {/* <Link to={game.Morelink}>
                  //       <div className="more-button rounded-[5px] !px-[8px] !py-[6px] max-w-[130px] !mx-auto">
                  //         {i18n("Read More")}
                  //       </div>
                  //     </Link> */}
                  //         </Box>
                  //       </Box>
                  //     </Box>
                  //     {/* <Box className="md:hidden">
                  //   <LazyLoadImage
                  //     src={game.image_mobile}
                  //     alt={game.title}
                  //     className="block md:hidden rounded-[20px] md:rounded-[30px] object-cover w-full h-[150px]"
                  //     data-splide-lazy="path-to-the-image"
                  //   />
                  // </Box> */}
                  //     <Flex
                  //       textAlign={"left"}
                  //       marginY={"1"}
                  //       paddingX="2"
                  //       justifyContent={"space-between"}
                  //       alignItems={"center"}
                  //     >
                  //       <Flex
                  //         flexDirection={"column"}
                  //         gap={1}
                  //         alignItems={"flex-start"}
                  //         justifyContent={"flex-start"}
                  //         w={"80%"}
                  //         maxW={"fit-content"}
                  //       >
                  //         <Text
                  //           className=" text-black group-hover:text-white transition-colors duration-200 text-sm md:text-base font-bold"
                  //           w={"100%"}
                  //           maxW={"fit-content"}
                  //           isTruncated
                  //         >
                  //           {i18n(game.title)}
                  //         </Text>
                  //         <Text className="text-light-gray-2 group-hover:text-base-light-white2 text-xs md:text-sm ">
                  //           {i18n(game.type)}
                  //         </Text>
                  //       </Flex>
                  //       <Image
                  //         src={typeIconConfig[game.type]}
                  //         alt={game.title}
                  //         className="w-4 h-4 transition-all duration-200 group-hover:filter brightness-0 invert"
                  //         filter="grayscale(100%)"
                  //       />
                  //     </Flex>
                  //   </Box>
                  // </Box>
                ))
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Products;
