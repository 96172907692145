import React, { useContext, useEffect, useRef, useState } from "react";
import Carousel from "../../components/Carousel";
import Feature from "../../components/Feature";
import GameCarousel from "../../components/GameCarousel";
import AllGameCarousel from "../../components/AllGameCarousel";
import Footer from "../../components/Footer";
import IntroBlock from "../../components/IntroBlock";
import LevelUp from "../../components/LevelUp";
import Contact from "../../components/Contact";
import { ContactScrollContext } from "../../store/triggerScrollToContact";
import CarouselV2 from "../../components/Carousel_v2";
import FeatureV2 from "../../components/Feature_v2";
import ContactV2 from "../../components/Contact_v2";
import LevelUpV2 from "../../components/LevelUp_v2";
import { Box, Flex } from "@chakra-ui/react";
import GameCategories from "../../components/GameCategories";
import TopGames from "../../components/TopGames";

const Home = () => {
  const contactRef = useRef(null);

  // const [firstRender, setFirstRender] = useState(true);

  const scrollCtx = useContext(ContactScrollContext);

  useEffect(() => {
    if (scrollCtx.state) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollCtx]);

  return (
    <section>
      <GameCategories />
      <CarouselV2 />
      <Box
        display={{
          base: "block",
          md: "none",
        }}
      >
        <TopGames />
      </Box>
      <Flex
        flexDirection={"column"}
        w={"100%"}
        className="gap-4 md:gap-4 lg:gap-6 px-3 md:px-4 lg:px-[26px] py-12 pt-2 md:pt-0"
      >
        <AllGameCarousel type="Slot" />
        <AllGameCarousel type="Crash" />
        {/* <AllGameCarousel type="Blockchain" />
        <AllGameCarousel type="SICBO" /> */}
        {/* <AllGameCarousel type="Fish" /> */}
        {/* <AllGameCarousel /> */}
        {/* <IntroBlock /> */}
        {/* <div className="hidden xl:block">
          <FeatureV2 />
        </div> */}
        {/* <LevelUpV2 promo="Promo" /> */}
        {/* <ContactV2 ref={contactRef} /> */}
        {/* <Box className="block xl:hidden">
          <FeatureV2 />
        </Box> */}
      </Flex>
    </section>
  );
};

export default Home;
