import React from "react";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";
import { Box, Image, useDisclosure } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import MenuContent from "./MenuContent";

const MotionBox = motion(Box);

const MenuMobile = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box className="mobile_show">
      <MotionBox
        onClick={onToggle}
        whileTap={{ scale: 0.95 }}
        cursor="pointer"
        borderWidth="1px"
        borderColor="#BEBEBE"
        borderStyle="solid"
        borderRadius={"27px"}
        paddingX={3}
        paddingY={5}
      >
        <Image
          src={`${COMMON_WEB_PATH}/WSKYgameSample/icon/menu.svg`}
          alt="menu"
          w="30px"
          h="30px"
        />
      </MotionBox>

      <AnimatePresence>
        {isOpen && <MenuContent onClose={onToggle} key="menu-content" />}
      </AnimatePresence>
    </Box>
  );
};

export default MenuMobile;
