import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import zh_chs from "./zh_chs.json";
import th from "./th.json";
import tw from "./tw.json";
import viet from "./viet.json";
import id from "./id.json";

import { userLanguage } from "./detectLang";

const defaultLang = userLanguage();
i18n.use(initReactI18next).init({
  debug: false,
  lng: "tw",
  resources: {
    en: { translation: en },
    zh_chs: { translation: zh_chs },
    th: { translation: th },
    tw: { translation: tw },
    id: { translation: id },
    viet: { translation: viet },
  },
  fallbackLng: "tw",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
