import React, { useRef, useState, useEffect } from "react";
import { Box, Flex, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";

/**類似紙牌堆疊的輪播效果，頂部卡片可拖拽，點擊左右箭頭可切換卡片*/
const StackedCarousel = ({ carouselData }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // 當前顯示的卡片索引
  const [direction, setDirection] = useState(0); // 切換方向：1 表示向右，-1 表示向左，0 表示初始狀態
  const controls = useAnimation(); // 用於控制動畫
  const constraintsRef = useRef(null); // 用於設置拖拽約束的參考元素

  const isMobile = useBreakpointValue({ base: true, md: false }); // 根據螢幕大小判斷是否為手機

  const visibleCards = isMobile ? 3 : 4; // 計算堆疊中顯示的卡片數量，移動設備顯示 3 張，桌面設備顯示 4 張

  /**處理點擊下一張按鈕的事件 設置方向為向右(1)並更新當前索引*/
  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
    );
  };

  /**處理點擊上一張按鈕的事件 設置方向為向左(-1)並更新當前索引*/
  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
    );
  };

  /**
   * 處理拖拽結束事件
   * 根據拖拽距離判斷是否切換卡片
   * @param {Object} event - 事件對象
   * @param {Object} info - 拖拽信息，包含偏移量等
   */
  const handleDragEnd = (event, info) => {
    const threshold = 100; // 設置拖拽閾值，超過這個距離才會觸發切換
    if (info.offset.x < -threshold) {
      // 向左拖動超過閾值，切換到下一張
      handleNext();
    } else if (info.offset.x > threshold) {
      // 向右拖動超過閾值，切換到上一張
      handlePrev();
    } else {
      // 拖動距離不夠，卡片回到原位

      controls.start({ x: 0 });
    }
  };

  /**
   * 獲取要顯示的卡片索引數組
   * 從當前索引開始，計算接下來幾張卡片的索引
   * @returns {Array} 要顯示的卡片索引數組
   */
  const getVisibleCardIndices = () => {
    const indices = [];
    for (let i = 0; i < visibleCards; i++) {
      // 使用取模操作確保索引不超出範圍
      let index = (currentIndex + i) % carouselData.length;
      indices.push(index);
    }
    return indices;
  };

  // 創建要顯示的卡片索引數組
  const visibleCardIndices = getVisibleCardIndices();

  const getRandomOffset = (index) => {
    // 使用索引創建一個固定的隨機種子，確保每次渲染相同索引的卡片位置一致
    const seed = index * 13.37;

    // 頂部卡片不偏移
    if (index === 0) return { x: 0, y: 0, rotate: 0 };

    // 為底層卡片創建微小的隨機偏移
    const xOffset = Math.sin(seed) * 18; // x 方向偏移範圍為 -18px 到 +18px
    const yOffset = Math.abs(Math.cos(seed) * 5) + index * 10; // y 方向偏移基於索引值遞增，加上小的隨機變化
    const rotateOffset = Math.sin(seed) * 2; // 旋轉角度偏移範圍為 -2.0 到 +2.0 度

    return {
      x: xOffset,
      y: yOffset,
      rotate: rotateOffset,
    };
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      h={{ base: "90vw", md: "31vw", lg: "340px" }}
      w="full"
      maxW="1680px"
      mx="auto"
      px={{ base: 8, md: 4, lg: "26px" }}
      pt={{ base: 6, md: 4, lg: 8 }}
      pb={{ base: "20px", md: 4, lg: 8 }}
      overflow="hidden"
    >
      {/* 拖拽約束容器 */}
      <Box
        ref={constraintsRef}
        position="relative"
        w="full"
        h="full"
        borderRadius="52px"
        overflow="visible"
      >
        {/* 堆疊卡片區域 處理進入和離開動畫 */}
        <AnimatePresence initial={false} custom={direction}>
          {visibleCardIndices.map((cardIndex, stackIndex) => {
            const isTopCard = stackIndex === 0; // 判斷是否為頂部卡片
            const offset = getRandomOffset(stackIndex); // 獲取當前卡片的偏移值
            const game = carouselData[cardIndex]; // 獲取當前卡片的數據

            // Card content wrapped with Link
            const CardContent = (
              <Box
                h="full"
                w="full"
                borderRadius="52px"
                overflow="hidden"
                bg={`rgba(0, 0, 0, ${0.02 * stackIndex})`}
                className={isTopCard ? "sm:menu-box-shadow" : ""} // 只為頂部卡片添加陰影效果
              >
                <LazyLoadImage
                  alt={game.title || "Carousel image"}
                  src={isMobile ? game.imageMobile : game.image} // 根據設備選擇圖片
                  className="object-cover w-full h-full relative rounded-[52px]"
                />
              </Box>
            );

            return (
              <motion.div
                key={`card-${cardIndex}`}
                custom={direction}
                initial={
                  // 初始動畫狀態
                  isTopCard && direction !== 0
                    ? {
                        // 頂部卡片從左側或右側進入
                        x: direction > 0 ? "100%" : "-100%",
                        zIndex: visibleCards - stackIndex,
                      }
                    : { zIndex: visibleCards - stackIndex }
                }
                // 正常顯示時的動畫狀態
                animate={{
                  // 頂部卡片居中，底層卡片根據偏移值定位
                  x: isTopCard ? 0 : offset.x,
                  y: offset.y,
                  rotate: offset.rotate,
                  scale: 1 - stackIndex * 0.04, // 底層卡片逐漸縮小
                  zIndex: visibleCards - stackIndex, // z-index 確保頂部卡片顯示在最上層
                  filter: `brightness(${1 - stackIndex * 0.08})`, // 底層卡片亮度降低，增加深度感
                }}
                // 離開動畫狀態
                exit={
                  isTopCard
                    ? {
                        x: direction < 0 ? "100%" : "-100%", // 頂部卡片向左側或右側離開
                        zIndex: visibleCards - stackIndex,
                      }
                    : { zIndex: visibleCards - stackIndex }
                }
                // 動畫過渡配置
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  willChange: "transform",
                  cursor: isTopCard ? "grab" : "default", // 只有頂部卡片顯示拖拽樣式
                  borderRadius: "52px",
                  boxShadow: `0 ${4 + stackIndex * 2}px ${
                    8 + stackIndex * 3
                  }px rgba(0, 0, 0, ${0.1 + stackIndex * 0.03})`,
                  transformOrigin: "center center",
                }}
                drag={isTopCard ? "x" : false} // 只有頂部卡片可拖拽
                dragConstraints={constraintsRef} // 拖拽約束，防止卡片被拖出容器
                onDragEnd={isTopCard ? handleDragEnd : undefined} // 拖拽結束事件處理
                dragElastic={0.1} // 拖拽彈性，數值越小越不彈性
                whileTap={isTopCard ? { cursor: "grabbing" } : {}} // 拖拽時改變滑鼠樣式
              >
                <Link
                  to={`/play/${game.uid}`}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    pointerEvents: isTopCard ? "auto" : "none", // 只有頂部卡片可點擊
                  }}
                  onClick={(e) => {
                    // 防止拖拽過程中觸發導航
                    if (isTopCard && e.currentTarget.dragging) {
                      e.preventDefault();
                    }
                  }}
                >
                  {CardContent}
                </Link>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </Box>

      {/* 左右箭頭 */}
      {/* <IconButton
        aria-label="Previous slide"
        icon={<CiCircleChevLeft size={30} />}
        onClick={handlePrev}
        position="absolute"
        left={{ base: "15px", md: "30px" }}
        top="50%"
        transform="translateY(-50%)"
        bg="!transparent"
        color={"!#060606"}
        _hover={{ bg: "!transparent" }}
        borderRadius="full"
        zIndex={3}
        size="lg"
      /> */}

      <IconButton
        aria-label="Next slide"
        icon={<LiaLongArrowAltRightSolid size={36} />}
        onClick={handleNext}
        position="absolute"
        right={{ base: "15px", md: "30px" }}
        top="3%"
        transform="translateY(-50%)"
        bg="!transparent"
        color={"#060606"}
        _hover={{ bg: "!transparent", color: "#48D498" }}
        borderRadius="full"
        zIndex={3}
        size="lg"
      />
    </Flex>
  );
};

export default StackedCarousel;
