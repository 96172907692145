export const COMMON_WEB_PATH = process.env.REACT_APP_AWS_PATH;

// import mainLogo from "../images/Logo/EG-LOGO.webp";
// import mainLogoWhite from "../images/Logo/EG-LOGO.webp";
// import mobileLogo from "../images/Logo/EG-Logo-only2.webp";
// import headerLogo from "../images/Logo/headerLogo.webp";
// import footerLogo from "../images/Logo/footerLogo.webp";
// import slotICON from "../images/icon/slotICON.webp";
// import blockchainICON from "../images/icon/blockchainICON.webp";
// import sicboICON from "../images/icon/sicboICON.webp";

// 取得當前網域
const currentDomain = window.domain_name || window.location.hostname;

// 預設 logo 路徑
let mainLogoPath = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/EG-LOGO.webp`;
let mainLogoWhitePath = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/EG-LOGO.webp`;
let mobileLogoPath = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/mk-logo-m.svg`;
let headerLogoPath = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/mk-logo.svg`;
let LogoBlack = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/mk-logo-m.svg`;
let headerLogoWidth = "80px";
let headerLogoHeight = "auto";
let headerLogoMdWidth = "94px";

// 根據不同網域設置不同的 logo 路徑
if (currentDomain === "911vip.co") {
  headerLogoPath = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/911LOGO.webp`;
  LogoBlack = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/911LOGO.webp`;
  headerLogoWidth = "100px";
  headerLogoHeight = "70px";
  headerLogoMdWidth = "100px";
} else if (currentDomain === "5285.com.tw") {
  headerLogoPath = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/5285LOGO.webp`;
  LogoBlack = `${COMMON_WEB_PATH}/WSKYgameSample/Logo/5285LOGO.webp`;
}

export const stylesConfig = {
  // mainLogo: mainLogoPath,
  // mainLogoWhite: mainLogoWhitePath,
  LogoBlack: LogoBlack,
  headerLogo: headerLogoPath,
  headerLogoWidth: headerLogoWidth,
  headerLogoHeight: headerLogoHeight,
  headerLogoMdWidth: headerLogoMdWidth,
};

export const typeIconConfig = {
  Slot: `${COMMON_WEB_PATH}/WSKYgameSample/icon/slot.svg`,
  Crash: `${COMMON_WEB_PATH}/WSKYgameSample/icon/crash.svg`,
  Blockchain: `${COMMON_WEB_PATH}/WSKYgameSample/icon/blockchain.svg`,
  SICBO: `${COMMON_WEB_PATH}/icon/WSKYgameSample/sicbo.svg`,
  Casino: `${COMMON_WEB_PATH}/icon/WSKYgameSample/casino.svg`,
  Bingo: `${COMMON_WEB_PATH}/icon/WSKYgameSample/bingo.svg`,
  Lottery: `${COMMON_WEB_PATH}/icon/WSKYgameSample/lottery.svg`,
};
