import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { typeIconConfig } from "../helpers/stylesConfig";
import FavoriteButton from "./FavoriteButton";
import GameTag from "./GameTag";
import { useTranslation } from "react-i18next";

const GameCard = ({ game }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_game = (key) => t(`game.${key}`);

  return (
    <Box key={game.title} className="rounded-xl group">
      <Box
        className="gameSlide-container game-box-shadow transition-colors duration-200 group-hover:bg-second-color"
        p={2}
        rounded={"18px"}
      >
        <GameTag game={game} />
        <Box position="relative">
          <LazyLoadImage
            src={game.image}
            alt={game.title}
            className="rounded-[18px] object-cover w-full h-[170px] sm:h-[200px]"
            data-splide-lazy="path-to-the-image"
          />
          <FavoriteButton gameId={game.uid} />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            rounded={"18px"}
            className="overlay opacity-0 group-hover:opacity-100 transition-opacity duration-200 bg-black/50 flex items-center justify-center"
          >
            <Box>
              {/* <Text>{i18n(game.title)}</Text> */}
              <Link to={`/play/${game.uid}`}>
                <div className="text-white play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2">
                  {i18n("Play Demo")}
                </div>
              </Link>
              {/* <Link to={game.Morelink}>
        <div className="more-button rounded-[5px] !px-[8px] !py-[6px] max-w-[130px] !mx-auto">
          {i18n("Read More")}
        </div>
      </Link> */}
            </Box>
          </Box>
        </Box>
        {/* <Box className="md:hidden">
    <LazyLoadImage
      src={game.image_mobile}
      alt={game.title}
      className="block md:hidden rounded-[20px] md:rounded-[30px] object-cover w-full h-[150px]"
      data-splide-lazy="path-to-the-image"
    />
  </Box> */}
        <Flex
          textAlign={"left"}
          marginY={"1"}
          paddingX="2"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flex
            flexDirection={"column"}
            gap={1}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            w={"90%"}
            maxW={"-webkit-fill-available"}
          >
            <Text
              className=" text-black group-hover:text-white transition-colors duration-200 text-sm md:text-base font-bold"
              w={"90%"}
              maxW={"150px"}
              isTruncated
            >
              {i18n_game(game.title)}
            </Text>
            <Text className="text-light-gray-2 group-hover:text-base-light-white2 text-xs md:text-sm ">
              {i18n(game.type)}
            </Text>
          </Flex>
          <Image
            src={typeIconConfig[game.type]}
            alt={game.title}
            className="w-4 h-4 transition-all duration-200 group-hover:filter brightness-0 invert"
            filter="grayscale(100%)"
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default GameCard;
