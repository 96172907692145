import React from "react";
import { useSelector } from "react-redux";
import { Placeholder } from "semantic-ui-react";

const LoadingImage = ({ className, children, commonLoading }) => {
  return commonLoading ? (
    <Placeholder
      className={`${className}`}
      //style={{ animationPlayState: "paused" }}
    >
      <Placeholder.Image />
    </Placeholder>
  ) : (
    children
  );
};

export default LoadingImage;
