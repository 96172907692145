import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { languageOptions, languageMapping } from "../../src/i18n/config";
import { Box, Flex, Text, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdCheck } from "react-icons/md";

const LanguageSettingsMobileV2 = () => {
  const { t, i18n } = useTranslation();
  const translate = (key) => t(`common.${key}`);
  const navigate = useNavigate();

  // 處理完整的選項列表，包含國旗URL
  const options = languageOptions.map((option) => ({
    value: option.value,
    label: languageMapping(option.value),
    flagUrl: `https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/${option.countryCode.toLowerCase()}.svg`,
  }));

  // 處理語言變更
  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Flex flexDirection={"column"} gap={12}>
      <Flex
        flexDirection={"column"}
        textAlign={"left"}
        gap={{ base: 6, md: 10 }}
        className="gap-x-10 gap-y-4"
      >
        <h1 className="text-black text-4xl md:text-5xl font-bold px-6 py-4 ">
          {translate("chooseLanguage")}
        </h1>

        <Box className="language-list">
          {options.map((option) => (
            <Flex
              key={option.value}
              onClick={() => handleChangeLanguage(option.value)}
              alignItems="center"
              gap={4}
              p={6}
              cursor="pointer"
              bg={i18n.language === option.value ? "#4ED89E" : "transparent"}
              _hover={{ bg: "#4ED89E" }}
              className="language-item"
              borderBottom="1px solid"
              borderColor="#E1E1E1"
            >
              <Box
                className="flag-container"
                width="30px"
                height="20px"
                overflow="hidden"
                borderRadius="md"
              >
                <Image
                  src={option.flagUrl}
                  alt={option.label}
                  width="30px"
                  height="20px"
                  objectFit="cover"
                  className="rounded-md"
                />
              </Box>
              <Text
                fontWeight={i18n.language === option.value ? "bold" : "normal"}
                fontSize="xl"
                color={i18n.language === option.value ? "#FAFAFA" : "black"}
              >
                {option.label}
              </Text>
              {i18n.language === option.value && (
                <Box
                  ml="auto"
                  className="selected-indicator"
                  bgColor={"white"}
                  borderRadius={"full"}
                >
                  <MdCheck color="#48D498" size={24} />
                </Box>
              )}
            </Flex>
          ))}
          <Flex justifyContent={"center"} mt={6}>
            <Button
              bgColor={"#4ED89E"}
              _hover={{ bgColor: "#48D498" }}
              px={24}
              py={6}
              textColor={"white"}
              onClick={() => navigate(-1)}
              fontSize={"xl"}
              className="game-box-shadow"
            >
              {translate("Back")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LanguageSettingsMobileV2;
