import React from "react";
import SearchButton from "./SearchButton";
import GameCategoryList from "./GameCategoryList";
import LevelUpV2 from "../../components/LevelUp_v2";
import LevelUpV3 from "../../components/LevelUp_v3";
import { Box } from "@chakra-ui/react";
import GameCategoryListV2 from "./GameCategoryList_v2";

const Sidebar = () => {
  return (
    <Box className="p-4 md:pt-4 lg:pt-8 h-full bg-light-green-2">
      <Box mb={4}>
        <GameCategoryListV2 />
      </Box>
      {/* <div className="mb-4">
        <SearchButton />
      </div>
      <div className="mt-4">
        <GameCategoryList />
      </div>
      <div className="mt-4">
        <LevelUpV3 />
      </div> */}
    </Box>
  );
};

export default Sidebar;
