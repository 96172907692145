import React, { useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link } from "react-router-dom";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { gameData } from "../helpers/gamelist";
import WrapperTitle from "./WrapperTitle";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { stylesConfig, typeIconConfig } from "../helpers/stylesConfig";
import { BiSolidTag } from "react-icons/bi";
import NewGameTag from "./NewGameTag";
import LazyImage from "../pages/components/Loading/LazyImage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_ALT } from "../constant";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import TopGameTag from "./GameTag";
import GameTag from "./GameTag";
import FavoriteButton from "./FavoriteButton";
import GameCard from "./GameCard";
import useWebsiteInfo from "../hooks/useWebsiteInfo";

const AllGameCarousel = ({ tag, type }) => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const currentLang = i18nInstance.language || "en";
  const currentGameData = gameData(currentLang); // 取當前語言的遊戲數據

  const { is911vip, is5285 } = useWebsiteInfo();

  const splideRef = useRef(null);

  const slideLeft = () => {
    if (splideRef.current) {
      splideRef.current.go("<"); //向左滑動一格Slide
    }
  };

  const slideRight = () => {
    if (splideRef.current) {
      splideRef.current.go(">"); //向右滑動一格Slide
    }
  };

  return (
    <Box className={`text-center ${type === "Slot" ? "mt-0" : "mt-2 md:mt-6"}`}>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={{
          base: 2,
          md: 4,
        }}
      >
        <WrapperTitle
          icon={
            type ? (
              <Image
                src={typeIconConfig[type]}
                alt={type}
                className="w-8 h-8 md:w-8 md:h-8"
              />
            ) : (
              <HiMiniRocketLaunch />
            )
          }
          // icon={<HiMiniRocketLaunch />}
          //title={`${tag || ""} Products`}
          title={type ? `${i18n(type)}` : i18n("Products")} // 通過type來判斷是否有type，有就顯示type 沒有就顯示Products
        />
        <Flex flexDirection={"row"} justifyContent={"center"}>
          <Box
            className="group rounded-lg splide-arrow-button h-[35px] w-[35px] mr-2 flex justify-center items-center"
            onClick={slideLeft}
          >
            <MdArrowBackIosNew className="text-[#726190] group-hover:text-[#48D498] text-2xl p-1 font-extrabold" />
          </Box>
          <Box
            className="group rounded-lg splide-arrow-button h-[35px] w-[35px] mr-2 flex justify-center items-center"
            onClick={slideRight}
          >
            <MdArrowForwardIos className="text-[#726190] group-hover:text-[#48D498] text-2xl p-1 font-extrabold" />
          </Box>
          <Link to={`/products/${type}`}>
            <div className="view-all-button mr-2 text-base">
              {i18n("more")}
              {/* {type ? ` ${i18n(type)}` : ""} */}
            </div>
          </Link>
        </Flex>
      </Flex>
      <Splide
        ref={splideRef}
        aria-label="New Products"
        className="w-full !overflow-visible"
        options={{
          type: "slide",
          perPage: 5,
          gap: 15,
          autoplay: false,
          arrows: false,
          drag: "free",
          perMove: 1,
          pagination: false,
          lazyLoad: "nearby",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
          preloadPages: 4,
          keyboard: false,
          breakpoints: {
            640: {
              perPage: 2,
              gap: 5,
            },
            768: {
              perPage: 4,
              gap: 5,
            },
            1024: {
              perPage: 4,
              gap: 5,
            },
            1280: {
              perPage: 4,
              gap: 3,
            },
            1440: {
              perPage: 4,
              gap: 5,
            },
            1800: {
              //代表1800px以上的螢幕寬度
              perPage: 6,
            },
            2200: {
              perPage: 6,
            },
          },
        }}
      >
        {currentGameData
          .filter((item) => {
            // 定義需要過濾的遊戲 UID
            const filteredGameUids = ["CR2401", "CR2404", "CR2405", "CR2406"];

            // 如果是 911vip 或 5285 網站，且遊戲在過濾列表中，則不顯示該遊戲
            if ((is911vip || is5285) && filteredGameUids.includes(item.uid)) {
              return false;
            }
            // 根據類型過濾
            if (type) {
              return item.type === type;
            } else {
              return item;
            }
          })
          .map((game, index) => (
            <SplideSlide
              key={index}
              className="group rounded-xl !overflow-visible p-1"
            >
              <GameCard game={game} />
              {/* <Box
                className="gameSlide-container game-box-shadow transition-colors duration-200 group-hover:bg-second-color"
                p={2}
                rounded={"18px"}
              >
                <GameTag game={game} />
                <Box position="relative">
                  <LazyLoadImage
                    src={game.image}
                    alt={game.title}
                    className="rounded-[18px] object-cover w-full"
                    data-splide-lazy="path-to-the-image"
                  />
                  <FavoriteButton gameId={game.uid} />
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    rounded={"18px"}
                    className="overlay opacity-0 group-hover:opacity-100 transition-opacity duration-200 bg-black/50 flex items-center justify-center"
                  >
                    <Box>
                      
                      <Link to={`/play/${game.uid}`}>
                        <div className="text-white play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2">
                          {i18n("Play Demo")}
                        </div>
                      </Link>
                      
                    </Box>
                  </Box>
                </Box>
                
                <Flex
                  textAlign={"left"}
                  marginY={"1"}
                  paddingX="2"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Flex
                    flexDirection={"column"}
                    gap={1}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                    w={"80%"}
                    maxW={"fit-content"}
                  >
                    <Text
                      className=" text-black group-hover:text-white transition-colors duration-200 text-sm md:text-base font-bold"
                      w={"100%"}
                      maxW={"fit-content"}
                      isTruncated
                    >
                      {i18n(game.title)}
                    </Text>
                    <Text className="text-light-gray-2 group-hover:text-base-light-white2 text-xs md:text-sm ">
                      {i18n(game.type)}
                    </Text>
                  </Flex>
                  <Image
                    src={typeIconConfig[game.type]}
                    alt={game.title}
                    className="w-4 h-4 transition-all duration-200 group-hover:filter brightness-0 invert"
                    filter="grayscale(100%)"
                  />
                </Flex>
              </Box> */}
            </SplideSlide>
          ))}
        {/* <SplideSlide className="rounded-xl">
          <Box className="gameSlide-container">
            <Box className="bg-base-deep-gray rounded-xl object-cover w-full h-[170px] flex flex-col justify-center items-center">
              <Image
                src={stylesConfig.mainLogo}
                alt={COMMON_ALT}
                className="w-[80px] logo-drop-shadow"
                style={{ WebkitFilter: "brightness(1.5)" }}
              />
              <Text className="text-light-gray m-2">{i18n("Coming Soon")}</Text>
            </Box>

            <Box className="overlay rounded-xl">
              <Box className="overlay-content">
                <Text>{i18n("Coming Soon")}</Text>
                <Link to="/products">
                  <Box className="more-button rounded-[5px] !px-[8px] !py-[6px]">
                    {i18n("Read More")}
                  </Box>
                </Link>
              </Box>
            </Box>
          </Box>
        </SplideSlide> */}
      </Splide>
    </Box>
  );
};

export default AllGameCarousel;
