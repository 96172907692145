import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import searchICON from "../../images/icon/searchICON.webp";
import searchICON_mobile from "../../images/icon/searchICON_mobile.webp";
import { COMMON_WEB_PATH } from "../../helpers/stylesConfig";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";
import { Box } from "@chakra-ui/react";

const SearchButtonV2 = () => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const currentLang = i18nInstance.language || "en";
  const navigate = useNavigate();
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  const handleClick = () => {
    navigate("/products");
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center justify-center gap-3 !bg-[rgba(255,255,255,0.3)] rounded-[12px] px-4 pl-3 py-[7px] transition-colors duration-200 border-[1px] border-[#fff]"
    >
      <Box className="flex items-center">
        <CiSearch className="w-6 h-6 mr-2 text-base-light-white2" />
        <Box className="border-r-2 border-[#fff] h-6 mx-2" />
      </Box>

      {/* <img
        src={`${ICON_PATH}/searchICON_mobile.webp`}
        alt="search"
        className="w-6 h-6 block md:hidden"
      /> 
      <img src={searchICON} alt="search" className="w-6 h-6 hidden md:block" />*/}
      <span className="text-base text-white font-medium">
        {i18n("Search Games")}
      </span>
    </button>
  );
};

export default SearchButtonV2;
