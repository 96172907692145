import React from "react";
import { Link } from "react-router-dom";
import { stylesConfig } from "../helpers/stylesConfig";
import MenuContentInner from "./MenuContentInner";
import LanguageSettingsMobile from "./LanguageSettingsMobile";
import { motion } from "framer-motion";
import { COMMON_ALT } from "../constant";
import closeIcon from "../images/icon/closeICON.webp";
import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import MenuContentInnerV2 from "./MenuContentInner_v2";
import { CiGlobe } from "react-icons/ci";
import { useTranslation } from "react-i18next";

const MotionBox = motion(Box);

const MenuContent = ({ onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  // 淡入淡出動畫
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
    },
  };

  // 選單滑入滑出動畫
  const menuVariants = {
    hidden: {
      x: "100%",
      transition: { duration: 0 }, // 確保初始狀態立即設置
    },
    visible: {
      x: 0,
      transition: {
        type: "tween",
        ease: "easeOut",
        duration: 0.4,
      },
    },
    exit: {
      x: "100%",
      transition: {
        type: "tween",
        ease: "easeIn",
        duration: 0.3,
      },
    },
  };

  // 處理語言點擊並關閉菜單
  const handleLanguageClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <MotionBox
      position="fixed"
      zIndex={9}
      w="100%"
      h="100%"
      top={0}
      right={0}
      left={0}
      bottom={0}
      // className="black-mask"
      onClick={onClose}
      variants={overlayVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <MotionBox
        onClick={(e) => e.stopPropagation()}
        className="menu-right box-shadow-right"
        variants={menuVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Flex flexDir="column" justifyContent="space-between" p={6} flex={1}>
          <Box as="section">
            <Flex alignItems="center" justifyContent="flex-end">
              <Box
                cursor="pointer"
                borderWidth="1px"
                borderColor="#fff5ea"
                borderStyle="solid"
                borderRadius={"27px"}
                paddingX={3}
                paddingY={5}
                color={"#fff5ea"}
              >
                <IoClose
                  fontSize={30}
                  onClick={onClose}
                  alt={COMMON_ALT}
                  className="mobile_show"
                />
              </Box>
              {/* <Link to="/" onClick={onClose}>
                <Image
                  alt={COMMON_ALT}
                  src={stylesConfig.mobileLogo}
                  w="30px"
                  cursor="pointer"
                  className="mobile_show"
                />
              </Link> */}
            </Flex>
            <MenuContentInnerV2 onClose={onClose} />
          </Box>

          <VStack as="section" spacing={2} align="flex-start">
            <Link to="/language" cursor="pointer" onClick={handleLanguageClick}>
              <Flex alignItems="center" gap={4} paddingX={4} color={"white"}>
                <CiGlobe className="w-[26px] h-[26px]" />
                <span
                  className={`text-2xl font-medium transition-colors duration-200`}
                >
                  {i18n("languages")}
                </span>
              </Flex>
            </Link>
          </VStack>
        </Flex>
      </MotionBox>
    </MotionBox>
  );
};

export default MenuContent;
