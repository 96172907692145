const { createContext, useState } = require("react");

const ContactScrollContext = createContext({
  state: false,
  trigger: () => {},
});

const ContactScrollProvider = ({ children }) => {
  const [scrollState, setScrollState] = useState(false);

  const triggerScrollState = () => {
    setScrollState(true);
    setTimeout(() => {
      setScrollState(false);
    }, 1000);
  };

  return (
    <ContactScrollContext.Provider
      value={{ state: scrollState, trigger: triggerScrollState }}
    >
      {children}
    </ContactScrollContext.Provider>
  );
};

export { ContactScrollProvider, ContactScrollContext };
