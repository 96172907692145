import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { gameData } from "../../../helpers/gamelist";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";

const GameInfo = () => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const currentLang = i18nInstance.language || "en";

  const { gameUid } = useParams(); //useParams是一個hook，他的作用是獲取路由參數

  // 獲取當前語言的遊戲數據
  const currentGameData = useMemo(() => {
    return gameData(currentLang);
  }, [currentLang]);

  const gameConfig = useMemo(() => {
    //useMemo是一個hook，他的作用是為了避免重複渲染，當gameUid改變的時候，才會重新渲染
    return currentGameData.find((game) => {
      //find是一個方法，他的作用是找到gameData裡面的game，並且把他返回出來
      return game.uid === gameUid; //如果gameData裡面的game的uid等於gameUid，就返回出來
    });
  }, [gameUid, currentGameData]);

  const {
    //Demolink = "",
    type = "",
    title = "",
    intro = "",
    maxOdds = "",
    RTP = "",
    Resolution = "",
    DemoSiteRTP = "",
  } = gameConfig || {}; //空對象作為默認值，避免解構出錯
  //意思是如果gameConfig里面没有{}裡面這些属性，就给他一个空字符串

  const infoItems = [
    { label: "Type", value: type },
    { label: "Max Odds", value: maxOdds },
    { label: "RTP", value: RTP },
    { label: "Demo Site RTP", value: DemoSiteRTP },
    { label: "Resolution", value: Resolution },
  ];

  return (
    <div>
      {infoItems.map((item, index) => (
        <div
          key={index}
          className="grid grid-cols-2 items-center text-light-gray text-base font-medium border-b border-light-gray py-3"
        >
          <span>{i18n(item.label)}</span>
          <span>{item.value}</span>
        </div>
      ))}
    </div>
  );
};

export default GameInfo;
