import React from "react";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Image,
} from "@chakra-ui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import { CiGlobe } from "react-icons/ci";
import { languageOptions, languageMapping } from "../../i18n/config";

const LanguageSettings = () => {
  const { t, i18n } = useTranslation();

  const options = languageOptions.map((option) => ({
    value: option.value,
    label: languageMapping(option.value),
    flagUrl: `https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/${option.countryCode.toLowerCase()}.svg`,
  }));

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
  };

  const getCurrentLanguageLabel = () => {
    const currentOption = options.find((opt) => opt.value === i18n.language);
    return currentOption ? currentOption.label : "Select Language";
  };

  return (
    <Flex justifyContent={"center"} alignItems={"center"} position={"relative"}>
      <Menu>
        <MenuButton
          minW="0"
          w={"150px"}
          as={Button}
          rightIcon={<IoMdArrowDropdown />}
          className="!pl-8 px-6 py-1.5 !rounded-[12px] border border-white !bg-white/30 !text-white min-w-[150px]"
        >
          <CiGlobe className="absolute left-2 top-1/2 transform -translate-y-1/2 w-6 h-6 text-base-light-white2" />
          <span className="ml-2">{getCurrentLanguageLabel()}</span>
        </MenuButton>
        <MenuList
          className="!bg-white p-2 !rounded-[12px]"
          minW="0"
          w={"150px"}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => handleChangeLanguage(option.value)}
              className="flex items-center gap-2 !bg-white !text-black px-4 !py-2 hover:!bg-light-green rounded-xl"
            >
              <Image
                src={option.flagUrl}
                alt={option.label}
                className="w-6 h-4 object-cover rounded-md"
              />
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default LanguageSettings;
